import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NetworkPage from 'views/network-page';
import DevicePage from 'views/device-page';
import ControlpointPage from 'views/controlpoint-page';
import Dashboard from 'views/dashboard';
import SubscriptionPrices from 'views/subscriptions/SubscriptionPrices';
import PowerUsagePage from 'views/powerusage/index';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/networks/list',
            element: <NetworkPage />
        },
        {
            path: '/networks/:networkid/controlpoints',
            element: <ControlpointPage />
        },
        {
            path: '/networks/:networkid/devices',
            element: <DevicePage />
        },
        {
            path: '/devices/list',
            element: <DevicePage />
        },
        {
            path: '/controlpoints',
            element: <ControlpointPage />
        },
        {
            path: '/powerusage',
            element: <PowerUsagePage />
        },
        {
            path: '/subscriptions',
            element: <SubscriptionPrices />
        },
    ]
};

export default MainRoutes;
