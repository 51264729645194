// material-ui
import {
    ButtonGroup,
    Grid, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import RoomIcon from '@mui/icons-material/Room';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

// import { WsHandler } from 'zimi/messaging/WsHandler';

// ==============================|| SAMPLE PAGE ||============================== //


interface IProps {
    groupingOptions?: string;
    onDisplayOptionsChange: (options: string) => void; 
}
const GroupingOptions = (props: IProps) => {


    const theme = useTheme();
    const StyledButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            '&.Mui-disabled': {
                border: 0
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius
            }
        }
    }));

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };



    // const [displayOptions, setDisplayOptions] = React.useState(() => ['', '']);

    const handleDisplayOptions = (event: React.SyntheticEvent, newFormats: string) => {
        // setDisplayOptions(newFormats);
        props.onDisplayOptionsChange(newFormats)
    };

    // const displayState = displayOptions.find(a => a === 'state') ? true : false;
    // const displayActions = displayOptions.find(a => a === 'actions') ? true : false;
    // const displaySettings = displayOptions.find(a => a === 'settings') ? true : false;



    return (
        <MainCard key={'cps_grouping_options'}>
            <>

                <Grid item >
                    <Typography variant="subtitle1" color="inherit">
                        Grouping options
                    </Typography>
                    <StyledButtonGroup
                        size="small"
                        onChange={handleDisplayOptions}
                        aria-label="text formatting"
                        color="secondary"
                        value={props.groupingOptions}
                        exclusive 
                    >
                        <ToggleButton value="room" aria-label="room">
                            <RoomIcon />
                            Room
                        </ToggleButton>
                        <ToggleButton value="cpType" aria-label="type">
                            <ControlPointDuplicateIcon />
                            Type
                        </ToggleButton>
                        <ToggleButton value="serialNumber" aria-label="device">
                            <DeviceHubIcon />
                            Device
                        </ToggleButton>

                    </StyledButtonGroup>
                </Grid>

            </>

        </MainCard>
    );
};

export default GroupingOptions;
