// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconBoxModel, IconCpu, IconCpu2 } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBoxModel,
    IconCpu,
    IconCpu2
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    title: <FormattedMessage id="dashboard" />,
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'networks',
            title: <FormattedMessage id="networks" />,
            type: 'item',
            url: '/networks/list',
            icon: icons.IconBoxModel,
            breadcrumbs: true
        },
        {
            id: 'devices',
            title: <FormattedMessage id="devices" />,
            type: 'item',
            url: '/devices/list',
            icon: icons.IconCpu,
            breadcrumbs: true
        },
        {
            id: 'controlpoints',
            title: <FormattedMessage id="controlpoints" />,
            type: 'item',
            url: '/controlpoints',
            icon: icons.IconCpu2,
            breadcrumbs: true
        },
        {
            id: 'powerusage',
            title: <FormattedMessage id="powerusage" />,
            type: 'item',
            url: '/powerusage',
            icon: icons.IconCpu2,
            breadcrumbs: true
        },
        {
            id: 'subscriptions',
            title: <FormattedMessage id="subscriptions" />,
            type: 'item',
            url: '/subscriptions',
            icon: icons.IconCpu2,
            breadcrumbs: true
        }

    ]
};

export default other;
