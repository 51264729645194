// material-ui
import {
    Grid, Menu, MenuItem, MenuList, Select, TextField, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';


import { IOrganization } from 'zimi/blueprint';
import { Link, NavLink, unstable_HistoryRouter } from 'react-router-dom';

// import { WsHandler } from 'zimi/messaging/WsHandler';

// ==============================|| SAMPLE PAGE ||============================== //


interface IProps {
    networks: IOrganization[];
    selectedNetwork?: string
    onNetworksSelectedChange: (options: string) => void;
}
const NetworkSwitcher = (props: IProps) => {

    // const [displayOptions, setDisplayOptions] = React.useState(() => ['', '']);

    const handleDisplayOptions = (event: React.SyntheticEvent, newFormats: string) => {
        // setDisplayOptions(newFormats);
        props.onNetworksSelectedChange(newFormats)
    };

    return (
        <MainCard key={'cps_network_selector'}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="inherit">
                    Network selector
                </Typography>

                {/* <Autocomplete
                                    id="network-select-autocomplete"
                                    options={props.networks}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Networks" />}
                                    onChange={ (event) => {
                                        
                                    }}
                                /> */}
                <Select id='network-select' value={props.selectedNetwork !== undefined ? props.selectedNetwork : ''} onChange={(e) => {
                    const selectedId = e.target.value;

                }} >
                    {/* <MenuList> */}
                    <MenuItem value="">Select an option</MenuItem>


                    {props.networks.map(network => {
                        return (
                            // <Link ref={`/networks/${network.id}/controlpoints`}>
                            <MenuItem key={`network-select-menu-${network.id}`} value={network.id} >
                                <Link to={`/networks/${network.id}/controlpoints`}>
                                    <Typography>
                                        {network.name}

                                    </Typography>

                                </Link>
                            </MenuItem>
                            // </Link>
                        )
                    })}
                    {/* </MenuList> */}
                </Select>


            </Grid>
        </MainCard>
    );
};

export default NetworkSwitcher;
