// material-ui
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { devicesLoad, orgsLoad } from 'store/slices/zimiBlueprint';
import { gridSpacing } from 'store/constant';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'store';
import { IDevice } from 'zimi/blueprint';
import { useParams } from 'react-router-dom';
import DevicesList from './DevicesList';

// ==============================|| SAMPLE PAGE ||============================== //

const DevicePage = () => {

    const dispatch = useDispatch();

    const { networkid } = useParams()

    const user = useAuth().getCurrentUser()
    const theme = useTheme();

    const devices = useSelector((state) => state.blueprint.deviceState.devices.filter(dev => networkid !== undefined ? dev.organizationId === networkid : true));
    const orgs = useSelector((state) => state.blueprint.orgState.orgs.filter(org => networkid !== undefined ? org.id === networkid : true));



    useEffect(() => {
        user?.getIdToken()
            .then(jwt => {
                if (jwt) {
                    if (networkid) {
                        dispatch(devicesLoad(jwt, networkid));
                        dispatch(orgsLoad(jwt, networkid));

                    }
                    else {
                        dispatch(devicesLoad(jwt));
                        dispatch(orgsLoad(jwt))
                    }
                }
            })
    }, [networkid]);



    const deviceCard = (device: IDevice) => {

        const names = ` ${device.outputName1 ? device.outputName1 : ''} : ${device.outputName2 ? device.outputName2 : ''} : ${device.outputName3 ? device.outputName3 : ''} : ${device.outputName4 ? device.outputName4 : ''} `
        return <Grid item sm={6} md={3} key={'device_card_grid_' + device.id}>
            <Card sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
                <CardHeader
                    sx={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}
                    title={
                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                            {names}
                        </Typography>
                    }
                />
                <CardContent>
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item>
                            <Typography variant="subtitle2" color="inherit">
                                id: {`${device.id} `}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                serial: {`${device.serialNumber} `}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                Names: {names}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                Type: {` ${device.deviceType}`}
                            </Typography>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    }

    const deviceCardList = (devices: IDevice[]) => {
        return <Grid container spacing={gridSpacing}>

            {devices.map((device => {
                return deviceCard(device)
            }))}
        </Grid>
    }


    const deviceCardListElements = deviceCardList(devices);

    const deviceListElements = <DevicesList devices={devices} orgs={orgs}/>

    const org = orgs.find( org => org.id === networkid);
    
    


    return (
        <MainCard title="Device List">

        <div>

            {/* <MainCard title="Device Card">
                <Grid item xs={12}>
                    {deviceCardListElements}
                </Grid>
            </MainCard> */}
            {networkid === undefined ?
                <Typography variant='h2'>
                    All Devices
                </Typography>
                : 
                <Typography variant='h2'>
                {org?.name} Devices
            </Typography>
}
            <DevicesList devices={devices} orgs={orgs} />
        </div>
        </MainCard>
    )
};

export default DevicePage;
