
import * as requestPromise from 'request-promise'
import { appConfig } from '../../config';
import useAuth from "hooks/useAuth";

import { InvitationsResponse } from '../../zimi/blueprint';
import moment from 'moment'


const HOST = process.env.HOST || appConfig.zimiserviceConfig.baseUrl;
// const PORT = process.env.PORT || 80;
const BASEURL = 'https://' + HOST  //+ (PORT !== '80' ? ':' + PORT : '');
// const BASEURLLOCAL = 'http://localhost:3000'
export const getInvitations = (jwt: string): Promise<InvitationsResponse> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            headers: { 'Authorization': 'Bearer ' + jwt },
            url: BASEURL + '/api/group/invitations',
            resolveWithFullResponse: true,
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export enum UpdateInvitationType {
    ACCEPT,
    REJECT
}

export const updateInvitation = (invitationToken: string, updateType: UpdateInvitationType): Promise<InvitationsResponse> => {
    return new Promise<any>((resolve, reject) => {
        useAuth().getCurrentUser()?.getIdToken()
            .then(jwt => {

                let updateUrl = '';
                switch (updateType) {
                    case UpdateInvitationType.ACCEPT:
                        updateUrl = '/api/group/' + invitationToken + '/acceptInvitation';
                        break;
                    case UpdateInvitationType.REJECT:
                        updateUrl = '/api/group/' + invitationToken + '/rejectInvitation';
                        break;
                }
                requestPromise.post({
                    headers: { 'Authorization': 'Bearer ' + jwt },
                    url: BASEURL + updateUrl,
                    resolveWithFullResponse: true,
                })
                    .then((ret: any) => {
                        // const body = JSON.parse(ret.body);
                        resolve(ret);
                    })
                    .catch((err: any) => {
                        reject(err);
                    });
            })
            .catch(err => {
                reject(err)
            })
    })
};

export interface IPowerUsageEntry {
    deviceId: string;
    timestamp: Date;
    usage: number;
}
export const getPowerUsages = ( jwt: string, deviceIds: string[], startDate: Date | null, endDate: Date | null, groupDuration: string): Promise<IPowerUsageEntry[]> => {

    return new Promise<IPowerUsageEntry[]>((resolve, reject) => {

        if (startDate && endDate) {

                    let devicesStr = '';
                    deviceIds.forEach((devId, index, array) => {
                        devicesStr += devId
                        if (index < deviceIds.length) {
                            devicesStr += '|';
                        }
                    })
                    const params = `devices=${devicesStr}&startDate=${moment(startDate).format("yyyy-M-DD")}&endDate=${moment(endDate).format("yyyy-M-DD")}&limit=10000&groupDuration=${groupDuration}`

                    // return new Promise<any>((resolve, reject) => {
                    requestPromise.get({
                        headers: { 'Authorization': 'Bearer ' + jwt },
                        url: BASEURL + '/api/powerusage?' + params,
                        resolveWithFullResponse: true,
                    })
                        .then((ret: any) => {
                            const body = JSON.parse(ret.body);
                            resolve(body);
                        })
                        .catch((err: any) => {
                            reject(err);
                        });
                    // });

        } else {
            reject(' date range not selected')

        }
    })
};