// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

// assets
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentTwoTone';
import AccountCircleTwoTone from '@mui/icons-material/AccountCircleTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import DevicesIcon from '@mui/icons-material/Devices';
import StreamIcon from '@mui/icons-material/Stream';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import ReportCard from 'ui-component/cards/ReportCard';
import { gridSpacing } from 'store/constant';
import { Link } from 'react-router-dom';

// ==============================|| SAMPLE PAGE ||============================== //

const Dashboard = () => {
    const theme = useTheme();

    return (<MainCard title="Dashboard">

        <Grid container spacing={gridSpacing}>
            <Grid item sm={3} >
                <Link to={`/networks/list`}>

                    <ReportCard
                        primary="##"
                        secondary="Networks"
                        color={theme.palette.secondary.main}
                        iconPrimary={StreamIcon}

                    />
                </Link>
            </Grid>
            <Grid item sm={3}>
                <ReportCard
                    primary="##"
                    secondary="Devices"
                    color={theme.palette.secondary.main}
                    iconPrimary={DevicesIcon}
                />
            </Grid>
            <Grid item sm={3}>
                <ReportCard
                    primary="##"
                    secondary="Invitations"
                    color={theme.palette.secondary.main}
                    iconPrimary={AccountCircleTwoTone}
                />
            </Grid>
            <Grid item sm={3}>
                <ReportCard
                    primary="#"
                    secondary="Subscriptions"
                    color={theme.palette.secondary.main}
                    iconPrimary={ShoppingCartTwoToneIcon}
                />
            </Grid>
        </Grid>



    </MainCard>)
};

export default Dashboard;
