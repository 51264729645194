import React from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import ToggleButton from '@mui/material/ToggleButton';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircle';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

// styles
const FeatureTitleWrapper = styled(CardContent)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? `${theme.palette.background.default} !important` : `${theme.palette.grey[100]} !important`,
    textAlign: 'left',
    paddingTop: 12,
    paddingBottom: '12px !important'
}));

const FeatureContentWrapper = styled(CardContent)(({ theme }) => ({
    borderLeft: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? `${theme.palette.background.default} !important` : `${theme.palette.grey[200]} !important`,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        fontSize: '1.25rem',
        padding: '40px 16px'
    }
}));

const PopularBadgeWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.secondary.main,
    color: '#fff',
    display: 'inline-block',
    padding: '40px 40px 5px',
    fontSize: '0.8125rem',
    position: 'absolute',
    top: -24,
    right: -55,
    transform: 'rotate(45deg)'
}));

type plan = {
    id: number;
    popular: boolean;
    title: string;
    price: {
        monthly: number;
        yearly: number;
    };
};

const plans: plan[] = [
    {
        id: 1,
        popular: false,
        title: 'Single',
        price: {
            monthly: 3,
            yearly: 30
        }
    },
    {
        id: 2,
        popular: true,
        title: 'Boutique',
        price: {
            monthly: 6,
            yearly: 60
        }
    },
    {
        id: 3,
        popular: false,
        title: 'Expansive',
        price: {
            monthly: 20,
            yearly: 200
        }
    }

];

type planListItem = {
    type: string;
    label: string;
    permission?: itemPermission;
};

type itemPermission = {
    yesno?: boolean[];
    value?: string[];
}

const planList: planListItem[] = [
    {
        type: 'group',
        label: 'Network count'
    },
    {
        type: 'list',
        label: 'Maximum Networks for user',
        permission: { value: ['1', '5', '6+'] }
    },
    {
        type: 'group',
        label: 'Device control'
    },
    {
        type: 'list',
        label: 'View device status',
        permission: { yesno: [true, true, true,] }
    },
    {
        type: 'list',
        label: 'Control devices',
        permission: { yesno: [true, true, true] }
    },
    {
        type: 'group',
        label: 'Power usage'
    },
    {
        type: 'list',
        label: 'Power usage history',
        permission: { yesno: [true, true, true] }
    },
    {
        type: 'list',
        label: 'Power usage comparisons',
        permission: { yesno: [false, true, true] }
    },
    {
        type: 'group',
        label: 'Device Settings'
    },
    {
        type: 'list',
        label: 'Update Settings',
        permission: { yesno: [false, true, true] }
    },
    {
        type: 'list',
        label: 'Setup Schedules',
        permission: { yesno: [false, true, true] }
    },
    {
        type: 'list',
        label: 'Setup Behavior links',
        permission: { yesno: [false, true, true] }
    },

    {
        type: 'group',
        label: 'Additional'
    },
    {
        type: 'list',
        label: 'API access to enable external apps',
        permission: { yesno: [true, true, true] }
    },

    {
        type: 'group',
        label: 'Subscribe'
    },
    {
        type: 'list',
        label: 'Link',
        permission: { value: ['true', 'true', 'true'] }
    },

];

const PlanList = ({ plan, view, priceFlag }: { plan: plan; view: number; priceFlag: string }) => {
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={3} md={3} sx={{ display: view !== plan.id ? { xs: 'none', sm: 'block' } : 'block' }}>
            <FeatureContentWrapper>
                {plan.popular && <PopularBadgeWrapper>Popular</PopularBadgeWrapper>}
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={{ fontSize: '1.25rem', fontWeight: 500, position: 'relative', color: theme.palette.primary.main }}
                        >
                            {plan.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 700,
                                '& > span': {
                                    fontSize: '1.25rem',
                                    fontWeight: 500
                                },
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '1.25rem',
                                    '& > span': {
                                        fontSize: '1rem'
                                    }
                                }
                            }}
                        >
                            <sup>$</sup>
                            {priceFlag === 'yearly' ? plan.price.yearly : plan.price.monthly}
                            <span>/{priceFlag === 'yearly' ? 'Year' : 'Month'}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </FeatureContentWrapper>
        </Grid>
    );
};

const ListItem = ({ item, index, view }: { item: boolean; index: number; view: number }) => (
    <Grid item xs={4} sm={3} md={3} sx={{ display: view !== index + 1 ? { xs: 'none', sm: 'block' } : 'block' }}>

        {item === true && (
            <Box sx={{ px: 3, py: 1.5 }}>
                <CheckCircleTwoToneIcon sx={{ color: 'success.dark' }} />
            </Box>
        )}
        {item === false && (
            <Box sx={{ px: 3, py: 1.5 }}>
                <RemoveRoundedIcon sx={{ opacity: '0.3' }} />
            </Box>
        )}
    </Grid>
);

const ListItemValue = ({ item, index, view }: { item: string; index: number; view: number }) => (
    <Grid item xs={4} sm={3} md={3} sx={{ display: view !== index + 1 ? { xs: 'none', sm: 'block' } : 'block' }}>

        { (
            <Box sx={{ px: 3, py: 1.5 }}>                
                <Typography variant='subtitle1'> {item}</Typography>
            </Box>
        )}

    </Grid>
);

const OrderButton = ({ period, index, popular }: { period: string; index: number; popular?: boolean }) => {
  
    let url = 'https://zimi-test.chargebee.com/hosted_pages/checkout';
    
    switch( index){
        case 1:
            if( period === 'monthly'){
                // Single, Monthly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Starter-AUD-Monthly&subscription_items[quantity][0]=1&layout=in_app&'
            }
            if( period === 'yearly'){
                // Single, Yearly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Starter-AUD-Yearly&subscription_items[quantity][0]=1&layout=in_app&'
            }

        break;
        case 2:
            if( period === 'monthly'){
                // Single, Monthly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=boutique-AUD-Monthly&subscription_items[quantity][0]=1&layout=in_app&'
            }
            if( period === 'yearly'){
                // Single, Yearly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=boutique-AUD-Yearly&subscription_items[quantity][0]=1&layout=in_app&'
            }
        break;

        case 3:
            if( period === 'monthly'){
                // Single, Monthly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=expansive-AUD-Monthly&subscription_items[quantity][0]=1&layout=in_app&'
            }
            if( period === 'yearly'){
                // Single, Yearly
                url = 'https://zimi-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=expansive-AUD-Yearly&subscription_items[quantity][0]=1&layout=in_app&'
            }
        
        break;
    
    }
    return (
    <Grid item xs={12} sm={3} md={3} sx={{ display: 'block' }}>
        <FeatureContentWrapper>
            <Button variant={popular ? 'contained' : 'outlined'} color={popular ? 'secondary' : 'primary'}
            href={url}
            >
                Subscribe
            </Button>
        </FeatureContentWrapper>
    </Grid>
    )

    };

// =============================|| PRICING - PRICE 2 ||============================= //

const SubscriptionPrices = () => {
    const theme = useTheme();
    const [priceFlag, setPriceFlag] = React.useState('monthly');

    const [view, setView] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newView: number) => {
        if (newView !== null) {
            setView(newView);
        }
    };

    return (
        <>
            <Stack direction="row" spacing={gridSpacing} justifyContent="center">
                <ButtonGroup disableElevation variant="contained" sx={{ mb: 3 }}>
                    <Button
                        size="large"
                        sx={{ bgcolor: priceFlag === 'yearly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('yearly')}
                    >
                        Annual
                    </Button>
                    <Button
                        size="large"
                        sx={{ bgcolor: priceFlag === 'monthly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('monthly')}
                    >
                        Monthly
                    </Button>
                </ButtonGroup>
            </Stack>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Card sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <CardContent>
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={view}
                                exclusive
                                onChange={handleChange}
                                sx={{
                                    width: '100%',
                                    '& > button': {
                                        border: 'none',
                                        borderRadius: '5px ​!important'
                                    },
                                    '& > button.Mui-selected': {
                                        background: `${theme.palette.background.default}!important`,
                                        color: theme.palette.primary.main
                                    }
                                }}
                            >
                                {plans.map((plan, index) => (
                                    <ToggleButton key={index} value={plan.id}>
                                        {plan.title}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <MainCard content={false} sx={{ textAlign: 'center' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3} />
                            {plans.map((plan, index) => (
                                <PlanList plan={plan} view={view} priceFlag={priceFlag} key={index} />
                            ))}
                        </Grid>
                        {planList.map((list, index) => (
                            <React.Fragment key={index}>
                                {list.type === 'group' && (
                                    <FeatureTitleWrapper>
                                        <Typography variant="subtitle1">{list.label}</Typography>
                                    </FeatureTitleWrapper>
                                )}
                                {list.type === 'list' && (
                                    <Grid
                                        container
                                        spacing={0}
                                        sx={{
                                            borderBottom: '1px solid',
                                            borderColor:
                                                theme.palette.mode === 'dark'
                                                    ? `${theme.palette.background.default} !important`
                                                    : `${theme.palette.grey[200]} !important`
                                        }}
                                    >
                                        <Grid item xs={8} sm={3} md={3}>
                                            <Box sx={{ px: 3, py: 1.5 }}>
                                                <Typography component="div" align="left" variant="body2">
                                                    {list.label}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {list.permission?.yesno?.map((item, i) => (
                                            <ListItem key={i} item={item} index={index} view={view} />
                                        ))}
                                        {list.permission?.value?.map((item, i) => (
                                            <ListItemValue key={i} item={item} index={index} view={view} />
                                        ))}
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3} />
                            <OrderButton period={priceFlag} index={1} />
                            <OrderButton period={priceFlag} index={2} popular />
                            <OrderButton period={priceFlag} index={3} />
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default SubscriptionPrices;
