// import { IOrgLoad, IDeviceLoad, IDataClear, IDeviceChangeFromGateway, IInvitationLoad, IWsConnected, IWsDisconnected } from "../../z/blueprintActions";
import { ORG_LOAD, DEVICE_LOAD, DATA_CLEAR, DEVICE_UPDATE_GATEWAY, INVITATION_LOAD, WS_CONNECTED, WS_DISCONNECTED, WS_ADDED, WS_REMOVED } from "../../zimi/constants";
import { IBlueprintState } from '../../zimi/auth';
// import { IDevice, IOrganization } from "../../zimi/blueprint";
import { IDevice, IOrganization, IDeviceUpdateGateway, Invitation } from "../../zimi/blueprint";
import { getDevices, getOrganizations, getOrganization } from '../../zimi/requests/blueprintRequests'
import { getInvitations } from "../../zimi/requests/zimiserverRequests";

import { ThunkDispatch as Dispatch } from "redux-thunk";
import { dispatch } from '../index';
import { WsHandler } from "zimi/messaging/WsHandler";



export default function blueprintReducers(
    state: IBlueprintState = {
        orgState: { orgs: [], loaded: false },
        deviceState: { devices: [], loaded: false },
        invitationState: { invitations: [], loaded: false },
        wss: { connectedZCC: [], clients: new Map<string, WsHandler>() }
    },
    action: IOrgLoad | IDeviceLoad | IDataClear | IDeviceChangeFromGateway | IInvitationLoad | IWsConnected | IWsDisconnected | IWsAdded | IWsRemoved
): IBlueprintState {
    switch (action.type) {
        case ORG_LOAD:

            const orgMap = new Map<string, IOrganization>();
            state.orgState.orgs.forEach(existingOrgs => {
                orgMap.set(existingOrgs.id, existingOrgs);
            })
            action.data.orgs.forEach((newOrg: any) => {
                orgMap.set(newOrg.id, newOrg)
            })
            const updatedOrgs: IOrganization[] = []
            orgMap.forEach(org => {
                updatedOrgs.push(org);
            })

            return { ...state, orgState: { orgs: updatedOrgs, loaded: true } };
        case DEVICE_LOAD:
            return { ...state, deviceState: { devices: action.data.devs, loaded: true } }
        case INVITATION_LOAD:
            return { ...state, invitationState: { invitations: action.data.invitations, loaded: true } }
        case DATA_CLEAR:
            return { ...state, orgState: { orgs: [], loaded: false }, deviceState: { devices: [], loaded: false }, invitationState: { invitations: [], loaded: false } }
        case DEVICE_UPDATE_GATEWAY:
            let update = action.data.dev;

            let newDevicesList = JSON.parse(JSON.stringify(state.deviceState.devices)) as IDevice[];
            if (newDevicesList) {
                const gatewayDevs = newDevicesList.filter(devGateway => (devGateway.id === update.gatewayId));
                gatewayDevs.forEach(gatewayDev => {
                    const matchedDevs = newDevicesList.filter(dev => (dev.organizationId === gatewayDev.organizationId && dev.meshDeviceId && parseInt(dev.meshDeviceId, 10) === update.meshId))
                    matchedDevs.forEach(matchedDev => {
                        Object.keys(update.data)
                            .forEach((key) => {
                                matchedDev[key] = update.data[key]
                            })
                        matchedDev.meta = { lastUpdateTime: new Date(Date.now()) };
                    })

                    const updatedDevices = state.deviceState.devices.map(dev => {
                        matchedDevs.forEach(matchedDev => {
                            if (matchedDev.id === dev.id) {
                                return matchedDev;
                            }
                        })
                        return dev;
                    })
                    return { ...state, deviceState: { devices: (updatedDevices), loaded: true } }
                })
            }
            return { ...state, deviceState: { devices: newDevicesList, loaded: true } }

        case WS_CONNECTED:
            const id = action.data.id;
            const newConnectedWS = [...state.wss.connectedZCC];
            newConnectedWS.push(id);
            return { ...state, wss: { connectedZCC: newConnectedWS, clients: state.wss.clients } }

        case WS_DISCONNECTED:
            const removeId = action.data.id;
            const newConnectedWsForDel = [...state.wss.connectedZCC];

            const index = newConnectedWsForDel.indexOf(removeId);
            newConnectedWsForDel.splice(index, 1);
            return { ...state }
            // return { ...state, wss: { connectedZCC: newConnectedWsForDel, clients: state.wss.clients } }

        case WS_ADDED:
            const clients = state.wss.clients;
            clients.set(action.data.zccId, action.data.ws)
            return { ...state, wss: { connectedZCC: state.wss.connectedZCC, clients} }

        case WS_REMOVED:
            const zccIdRemoved = action.data.zccId;
            const clientsRemoving = state.wss.clients;
            clientsRemoving.get(zccIdRemoved)?.close();
            clientsRemoving.delete(zccIdRemoved);
            return { ...state }

        default:
            return state;
    }
    // return state;
}


export interface IOrgLoad {
    type: ORG_LOAD;
    data: { orgs: IOrganization[] }
}

function loadOrgs(orgs: IOrganization[]): IOrgLoad {
    return {
        type: ORG_LOAD,
        data: { orgs },
    };
}

export interface IDeviceLoad {
    type: DEVICE_LOAD;
    data: { devs: IDevice[] }
}

function loadDevices(devs: IDevice[]): IDeviceLoad {
    return {
        type: DEVICE_LOAD,
        data: { devs },
    };
}

export interface IInvitationLoad {
    type: INVITATION_LOAD;
    data: { invitations: Invitation[] }
}

function loadInvitations(invitations: Invitation[]): IInvitationLoad {
    return {
        type: INVITATION_LOAD,
        data: { invitations },
    };
}

export interface IDeviceChangeFromGateway {
    type: DEVICE_UPDATE_GATEWAY,
    data: { dev: IDeviceUpdateGateway }
}

export function updateDevice(device: IDeviceUpdateGateway): IDeviceChangeFromGateway {
    return {
        type: DEVICE_UPDATE_GATEWAY,
        data: { dev: device }
    }
}

export interface IDataClear {
    type: DATA_CLEAR
}

function clearData(): IDataClear {
    return {
        type: DATA_CLEAR
    }
}

export interface IWsAdded {
    type: WS_ADDED,
    data: { zccId: string, ws: WsHandler }
}

export function wsAdded( id: string, ws: WsHandler): IWsAdded {
    return {
        type: 'WS_ADDED',
        data: { zccId: id, ws }
    }
}

export interface IWsRemoved {
    type: WS_REMOVED,
    data: { zccId: string }
}

export function wsRemoved(id: string): IWsRemoved {
    return {
        type: 'WS_REMOVED',
        data: { zccId: id }
    }
}

export interface IWsConnected {
    type: WS_CONNECTED,
    data: { id: string }
}

export function wsConnected(id: string) {
    return {
        type: WS_CONNECTED,
        data: { id }
    }
}

export interface IWsDisconnected {
    type: WS_DISCONNECTED,
    data: { id: string }
}

export function wsDisconnected(id: string) {
    return {
        type: WS_DISCONNECTED,
        data: { id }
    }
}


export type BlueprintAction = IOrgLoad | IDeviceLoad;

export const orgsLoad = (jwt: string, orgId?: string) => {
    return async () => {

        const value = jwt;
        if (orgId) {
            getOrganization(value, orgId)
                .then(org => {
                    dispatch(loadOrgs([org.organization]));

                }).catch(err => console.error('getOrganization error ', err))
        } else {
            getOrganizations(value)
                .then(orgs => {
                    dispatch(loadOrgs(orgs.organizations.results));
                }).catch(err => console.error('getOrganizations error ', err))
        }
        // })
    }

};


export const devicesLoad = (jwt: string, orgId?: string) => {
    return async () => {

        if (jwt) {
            getDevices(jwt, orgId)
                .then(devices => {
                    dispatch(loadDevices(devices.devices.results));
                })
        }
    }
}

export const AddWs = (id: string) => {
    return async () =>{
        dispatch(wsAdded(id, new WsHandler(id)))
    }
}


// export const invitationsLoad = () => {
//     return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
//         const currentUser = firebase.auth().currentUser;
//         if (currentUser) {
//             currentUser.getIdToken()
//                 .then(value => {
//                     getInvitations(value)
//                         .then(invitations => {
//                             dispatch(loadInvitations(invitations.invitations));
//                         })
//                 })
//         }
//     };
// }


export const deviceUpdateWithGatwayMessage = (deviceUpdate: IDeviceUpdateGateway) => {
    return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
        dispatch(updateDevice(deviceUpdate))
    }
}

export const initiateClearData = () => {
    return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
        dispatch(clearData())
    }
}

