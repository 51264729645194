// material-ui
import {
    Grid, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import React, { useEffect } from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import ControlpointRoomPage from './ControlPointRoom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';
import { AddWs, devicesLoad, orgsLoad, wsRemoved } from 'store/slices/zimiBlueprint';
import { devicesToControlPoints } from 'zimi/dataUtils';
import ControlpointNetwork from './ControlPointNetwork';
import { gridSpacing } from 'store/constant';
import DisplayOptions from './DisplayOptions';
import GroupingOptions from './GroupingOptions';
import NetworkSwitcher from './NetworkSwitcher';
// import { WsHandler } from 'zimi/messaging/WsHandler';

// ==============================|| SAMPLE PAGE ||============================== //


const ControlpointPage = (props: any) => {


    const { networkid } = useParams()
    const user = useAuth().getCurrentUser()

    const dispatch = useDispatch();
    const [displayOptions, setDisplayOptions] = React.useState(() => ['', '']);
    const [groupingOptions, setGroupingOptions] = React.useState(() => 'room');


    // const network = useSelector((state) => state.blueprint.orgState.orgs.find(org => org.id === networkid));
    const networks = useSelector((state) => state.blueprint.orgState.orgs);

    useEffect(() => {
        user?.getIdToken()
            .then(jwt => {
                if (jwt) {
                    if (networkid === undefined) {
                        dispatch(orgsLoad(jwt));
                        dispatch( devicesLoad(jwt))
                    } else {
                        dispatch(orgsLoad(jwt, networkid));
                        dispatch(devicesLoad(jwt, networkid));

                    }

                }
            })

        return () => {

        }
    }, [networkid]);



    const theme = useTheme();

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };


    return (
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={6} >
                    <DisplayOptions displayOptions={displayOptions} onDisplayOptionsChange={(options) => {
                        setDisplayOptions(options);
                    }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GroupingOptions groupingOptions={groupingOptions} onDisplayOptionsChange={(options) => {
                        setGroupingOptions(options);
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <NetworkSwitcher key={`network-switcher-network-${networkid}`} networks={networks} selectedNetwork={networkid}  onNetworksSelectedChange = { (networksSelected) => {}}/>
                </Grid>
                <Grid item xs={12}>
                    {networkid && <ControlpointNetwork networkid={networkid} displayOptions={displayOptions} groupingOptions={groupingOptions} />}
                </Grid>


                {networkid === undefined && networks.length > 0 && networks.map(network => {
                    return (
                        <Grid item xs={12}>
                            <ControlpointNetwork networkid={network.id} displayOptions={displayOptions} groupingOptions={groupingOptions} />                            
                        </Grid>
                    )
                    })}
            </Grid>
    );
};

export default ControlpointPage;
