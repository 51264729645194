// material-ui
import {

    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';


// assets
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';


import PowerIcon from '@mui/icons-material/Power';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PowerOffIcon from '@mui/icons-material/PowerOff';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WindPowerIcon from '@mui/icons-material/WindPower';
import GarageIcon from '@mui/icons-material/Garage';
import SettingsIcon from '@mui/icons-material/Settings';
import ScheduleIcon from '@mui/icons-material/Schedule';

// project imports
import { IZimiControlpoint, IZimiControlpointActions, IZimiControlpointControlBlind, IZimiControlpointControlDimmer, IZimiControlpointControlDoor, IZimiControlpointControlFan, IZimiControlpointControlLight, IZimiControlpointControlOutlet, IZimiControlpointControlSwitch } from 'zimi/types';
import { styled } from '@mui/styles';
import React, { useEffect } from 'react';
import { Box, Theme } from '@mui/system';
import PowerOff from '@mui/icons-material/PowerOff';
import useAuth from 'hooks/useAuth';
import { applyAction } from 'zimi/requests/cpActionsRequests';
import { gridSpacing } from 'store/constant';



const ControlpointCard = (props: { cp: IZimiControlpoint, display: { state: boolean, actions: boolean, settings: boolean } }) => {

    const theme = useTheme();
    const user = useAuth().getCurrentUser()

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            '&.Mui-disabled': {
                border: 0
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius
            }
        }
    }));

    const [formats, setFormats] = React.useState(() => ['bold', 'italic']);
    const handleFormat = (event: React.SyntheticEvent, newFormats: string[]) => {
        setFormats(newFormats);
    };

    const [mainLoading, setMainLoading] = React.useState(false)

    useEffect(() => {
        setMainLoading(false)
    }, [props.cp.control])

    const cpActionApply = (cpId: string, action: IZimiControlpointActions, params?: any) => {
        console.log('cpActionApply:  ', cpId, action, params);
        let namespace = ''
        let name: string = action;
        let payload: any = undefined;

        switch (action) {
            case 'TurnOn':
            case 'TurnOff':
                namespace = 'Zimi.PowerController';
                break;
            case 'Open':
                namespace = 'Zimi.ModeController';
                name = 'SetMode'
                payload = { mode: 'Position.Up' }
                break;
            case 'Close':
                namespace = 'Zimi.ModeController';
                name = 'SetMode'
                payload = { mode: 'Position.Down' }
                break;
            case 'SetBrightness':
                namespace = 'Zimi.BrightnessController';
                name = 'SetBrightness';
                payload = { brightness: params.brightness }
                break;
            case 'SetSpeed':
                namespace = 'Zimi.RangeController';
                name = 'SetRangeValue';
                payload = { rangeValue: params.speed }
                break;
            case 'OpenToPercentage':
                namespace = 'Zimi.PercentageController';
                name = 'SetPercentage';
                payload = { percentage: params.openPercentage }
                break;
        }

        return new Promise<void>((resolve, reject) => {

            user?.getIdToken()
                .then(jwt => {

                    // OPTIMIZE jwt get method. Might not have to get call all the time if there are waits in this method.
                    const payloadObj = {
                        event: {
                            directive: {
                                header: {
                                    namespace,
                                    name
                                },
                                endpoint: {
                                    endpointId: cpId,
                                    scope: {
                                        token: jwt,
                                        type: 'BearerToken'
                                    }
                                },
                                payload
                            }
                        }
                    }

                    if (name && namespace) {

                        applyAction(jwt, payloadObj)
                            .then(() => {
                                resolve()
                            })
                            .catch(err => {
                                reject(err)
                            })
                    }
                })
                .catch(err => {
                    reject('could not get accessToken')
                })
        })

    }

    const showIsOnState = (isOn: boolean, theme: Theme) => {

        return (

            <Grid item>
                <Typography variant="subtitle2" color="inherit">
                    Is On
                </Typography>

                <Switch
                    checked={isOn}
                    sx={{
                        color: theme.palette.success.dark,
                        '& .Mui-checked': { color: `${theme.palette.success.dark} !important` },
                        '& .Mui-checked+.MuiSwitch-track': { bgcolor: `${theme.palette.success.light} !important` }
                    }} />
            </Grid>
        )
    }

    const showLinearState = (percentage: number, text: string) => {
        return (
            <>
                <Grid item>
                    <Typography variant="caption">{text}</Typography>
                </Grid>
                <Grid item >
                    <LinearProgress variant="determinate" color='success' value={percentage} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">{percentage}%</Typography>
                </Grid>
            </>
        )
    }


    const showSteppedState = (value: number, maxValue: number, text: string) => {
        return (
            <>
                <Grid item>
                    <Typography variant="caption">{text}</Typography>
                </Grid>
                <Grid item >
                    <LinearProgress variant="determinate" color='success' value={Math.ceil(value / maxValue * 100)} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">{value}</Typography>
                </Grid>
            </>
        )
    }

    const ShowOnOffActions = (id: string,) => {

        const [loadingOff, setLoadingOff] = React.useState(() => false);
        const [loadingOn, setLoadingOn] = React.useState(() => false);


        return (
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant='subtitle2'> Set On Off </Typography>
                    <ButtonGroup>
                        <LoadingButton
                            key={'cp_action_off_' + id}
                            size="small"
                            onClick={() => {
                                setLoadingOff(true)
                                cpActionApply(id, 'TurnOff')
                                    .then(() => {
                                        setLoadingOff(false)
                                    })
                                    .catch(err => setLoadingOff(false))
                            }}
                            // endIcon={<SendIcon />}
                            loading={loadingOff}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            Turn Off
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_on_' + id}
                            size="small"
                            onClick={() => {

                                setLoadingOn(true)
                                cpActionApply(id, 'TurnOn')
                                    .then(() => {
                                        setLoadingOn(false)
                                    })
                                    .catch(err => setLoadingOn(false))
                            }}
                            // endIcon={<SendIcon />}
                            loading={loadingOn}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            Turn On
                        </LoadingButton>
                    </ButtonGroup>
                </Grid>
            </Grid >
        )
    }

    const showSetBrightnessActions = (id: string,) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'> Set Brightness </Typography>

                    <ButtonGroup>

                        <LoadingButton
                            key={'cp_action_brightness_0_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetBrightness', { brightness: 0 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            0
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_brightness_25_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetBrightness', { brightness: 25 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            25
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_brightness_50_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetBrightness', { brightness: 50 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            50
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_brightness_75_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetBrightness', { brightness: 75 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            75
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_brightness_100_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetBrightness', { brightness: 100 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            100
                        </LoadingButton>
                    </ButtonGroup>

                </Grid>
            </Grid>
        )
    }

    const showOpenCloseActions = (id: string,) => {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant='subtitle2'> Set Open Close </Typography>
                    <ButtonGroup>
                        <LoadingButton
                            key={'cp_action_close_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'Close')

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            Close
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_open_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'Open')

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            Open
                        </LoadingButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
        )
    }

    const showSetPositionActions = (id: string,) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'> Set Position </Typography>

                    <ButtonGroup>

                        <LoadingButton
                            key={'cp_action_position_0_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'OpenToPercentage', { openPercentage: 0 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            0
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_position_25_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'OpenToPercentage', { openPercentage: 25 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            25
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_position_50_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'OpenToPercentage', { openPercentage: 50 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            50
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_position_75_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'OpenToPercentage', { openPercentage: 75 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            75
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_position_100_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'OpenToPercentage', { openPercentage: 100 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            100
                        </LoadingButton>
                    </ButtonGroup>

                </Grid>
            </Grid>
        )
    }


    const showSetSpeedActions = (id: string,) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'> Set Speed </Typography>

                    <ButtonGroup>

                        <LoadingButton
                            key={'cp_action_speed_0_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 0 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            0
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_1_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 1 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            1
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_2_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 2 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            2
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_3_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 3 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            3
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_4_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 4 })
                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            4
                        </LoadingButton>

                        <LoadingButton
                            key={'cp_action_speed_5_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 5 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            5
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_6_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 6 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            6
                        </LoadingButton>
                        <LoadingButton
                            key={'cp_action_speed_7_' + id}
                            size="small"
                            onClick={() => {
                                cpActionApply(id, 'SetSpeed', { speed: 7 })

                            }}
                            // endIcon={<SendIcon />}
                            loading={false}
                            loadingPosition="center"
                            variant="outlined"
                        >
                            7
                        </LoadingButton>
                    </ButtonGroup>

                </Grid>
            </Grid>
        )
    }


    const showDisconnectedState = () => {
        return (
            <>
                <Grid item>
                    <Typography variant="caption"> Connection  </Typography>
                </Grid>
                <Grid item >
                    <LinearProgress variant="determinate" color='warning' value={100} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Disconnected</Typography>
                </Grid>
            </>
        )
    }

    const cp = props.cp;

    const onColor = 'success';
    const offColor = 'primary';

    const getCpElements = (cp: IZimiControlpoint) => {
        let cpElement = {
            icon: <div></div>,
            iconElement: <div></div>,
            iconState: false,
            iconNextAction: '',
            state: <div></div>,
            actions: <div></div>
        }
        // let iconElement;

        switch (cp.cpType) {
            case 'BLIND':
                const cpControlBlind = cp.control as IZimiControlpointControlBlind
                cpElement.icon = cpControlBlind.openPercentage > 0 ? <BlindsClosedIcon color={onColor} /> : <BlindsClosedIcon color={offColor} />
                cpElement.iconState = cpControlBlind.openPercentage > 0 ? true : false
                cpElement.iconElement = <BlindsClosedIcon />
                cpElement.iconNextAction = cpElement.iconState ? 'Close' : 'Open';
                cpElement.state =
                    <>
                        {showLinearState(cpControlBlind.openPercentage, 'Blind position')}
                    </>
                cpElement.actions = <>
                    {showOpenCloseActions(cp.cpId)}
                    {showSetPositionActions(cp.cpId)}
                </>

                break;
            case 'SWITCH':
                const cpSwitch = cp.control as IZimiControlpointControlSwitch;
                cpElement.iconState = cpSwitch.isOn ? true : false
                cpElement.iconNextAction = cpElement.iconState ? 'TurnOff' : 'TurnOn';

                cpElement.icon = cpSwitch.isOn === true ? <PowerSettingsNewIcon color={onColor} /> : <PowerSettingsNewIcon color={offColor} />;
                cpElement.iconElement = <PowerSettingsNewIcon />;

                const cpControlSwitch = cp.control as IZimiControlpointControlSwitch;
                cpElement.state = showIsOnState(cpControlSwitch.isOn, theme)
                cpElement.actions = <> {ShowOnOffActions(cp.cpId)} </>

                break;
            case 'LIGHT':
                const cpControlLight = cp.control as IZimiControlpointControlLight;
                cpElement.iconState = cpControlLight.isOn ? true : false
                cpElement.iconNextAction = cpElement.iconState ? 'TurnOff' : 'TurnOn';


                cpElement.icon = cpControlLight.isOn ? <LightbulbIcon color={onColor} /> : <LightbulbIcon color={offColor} />
                cpElement.iconElement = <LightbulbIcon />;
                cpElement.state = showIsOnState(cpControlLight.isOn, theme)
                cpElement.actions = <> {ShowOnOffActions(cp.cpId)} </>

                break;
            case 'DIMMER':
                const cpControlDimmer = cp.control as IZimiControlpointControlDimmer;
                cpElement.iconState = cpControlDimmer.isOn ? true : false
                cpElement.iconNextAction = cpElement.iconState ? 'TurnOff' : 'TurnOn';
                cpElement.icon = cpControlDimmer.isOn ? <TipsAndUpdatesIcon color={onColor} /> : <TipsAndUpdatesIcon color={offColor} />
                cpElement.iconElement = <TipsAndUpdatesIcon />;

                cpElement.state =
                    (
                        <>
                            {showIsOnState(cpControlDimmer.isOn, theme)}
                            {showLinearState(cpControlDimmer.brightness, 'Brightness')}
                        </>
                    )
                cpElement.actions = <>
                    {ShowOnOffActions(cp.cpId)}
                    {showSetBrightnessActions(cp.cpId)}
                </>

                break;
            case 'FAN':
                const cpControlFan = cp.control as IZimiControlpointControlFan;
                cpElement.iconState = cpControlFan.isOn ? true : false
                cpElement.iconNextAction = cpElement.iconState ? 'TurnOff' : 'TurnOn';
                cpElement.icon = cpControlFan.isOn ? <WindPowerIcon color={onColor} /> : <WindPowerIcon color={offColor} />
                cpElement.iconElement = <PowerSettingsNewIcon />;

                cpElement.state =
                    <>
                        {showIsOnState(cpControlFan.isOn, theme)}
                        {showSteppedState(cpControlFan.speed, 7, 'Speed')}

                    </>
                cpElement.actions = <>
                    {ShowOnOffActions(cp.cpId)}
                    {showSetSpeedActions(cp.cpId)}
                </>

                break;
            case 'DOOR':
                const cpControlDoor = cp.control as IZimiControlpointControlDoor
                cpElement.icon = cpControlDoor.openPercentage > 0 ? <GarageIcon color={onColor} /> : <GarageIcon color={offColor} />
                cpElement.iconElement = <GarageIcon />;
                cpElement.state =
                    <>
                        {showLinearState(cpControlDoor.openPercentage, 'Door position')}
                    </>
                cpElement.actions = <> {showOpenCloseActions(cp.cpId)} {showSetPositionActions(cp.cpId)}</>
                break;
            case 'OUTLET':
                const cpControlOutlet = cp.control as IZimiControlpointControlOutlet;
                cpElement.iconState = cpControlOutlet.isOn ? true : false
                cpElement.iconNextAction = cpElement.iconState ? 'TurnOff' : 'TurnOn';
                cpElement.icon = cpControlOutlet.isOn == true ? <PowerIcon color={onColor} /> : <PowerOff color={offColor} />;
                cpElement.iconElement = <PowerIcon />;
                cpElement.state = showIsOnState(cpControlOutlet.isOn, theme)
                cpElement.actions = <> {ShowOnOffActions(cp.cpId)} </>

                // <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                //     <Chip label={"TurnOn"} chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
                //     <Chip label={"TurnOff"} chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
                // </Stack>

                break;
        }
        if (cp.connected === false) {
            cpElement.state = showDisconnectedState()
        }
        return cpElement;

    }

    const cpElements = getCpElements(cp);

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={'cp_view_grid_' + cp.cpId}>
            <Card sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
                <CardHeader
                    sx={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}
                    title={
                        <Grid container spacing={gridSpacing} >
                            <Grid item xs={5} sx={{ color: cp.connected === true ? theme.palette.primary.main : theme.palette.primary.light }}>

                                {(cpElements.iconNextAction !== '') ?
                                    <LoadingButton variant='outlined' sx={ {width: '10px'}} disabled={!cp.connected}
                                     loading={mainLoading} color={cp.connected ? (cpElements.iconState ? 'success' : 'secondary') : 'inherit'} onClick={() => {
                                        setMainLoading(true);

                                        cpActionApply(cp.cpId, cpElements.iconNextAction as IZimiControlpointActions)
                                            .then(() => {
                                            })
                                            .catch()

                                        setTimeout(() => {
                                            setMainLoading(false)
                                        }, 5000)
                                    }}>
                                        {cpElements.iconElement}
                                    </LoadingButton>
                                    :
                                    <div>
                                        {cpElements.iconElement}
                                    </div>

                                }



                            </Grid>
                            <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h4" sx={{ color: cp.connected === true ? theme.palette.primary.dark : theme.palette.primary.light }}>
                                    {cp.name}
                                </Typography>
                            </Grid>
                            {/* <Grid item sm={2} /> */}

                        </Grid>
                    }
                />
                {(props.display.actions || props.display.state || props.display.settings) && <CardContent>
                    <Grid alignItems="center" justifyContent="center">
                        {props.display.state && <Grid item >
                            <Typography variant="subtitle1" color="inherit" >
                                State
                            </Typography>
                            {cpElements.state}
                            <br />
                        </Grid>
                        }

                        {props.display.actions && <Grid item alignContent={'center'}>
                            <Typography variant="subtitle1" color="inherit">
                                Actions
                            </Typography>
                            {cpElements.actions}
                            <br />
                        </Grid>}


                        {props.display.settings && <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                                Settings
                            </Typography>
                            <StyledToggleButtonGroup
                                size="small"
                                value={formats}
                                onChange={handleFormat}
                                aria-label="text formatting"
                                color="secondary"
                            >
                                <ToggleButton value="settings" aria-label="settings">
                                    <SettingsIcon />
                                </ToggleButton>
                                <ToggleButton value="schedule" aria-label="schedule">
                                    <ScheduleIcon />
                                </ToggleButton>

                            </StyledToggleButtonGroup>
                        </Grid>}
                    </Grid>
                </CardContent>
                }
            </Card>
        </Grid>)


};

export default ControlpointCard