// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    // const theme = useTheme();
    const zimiLogoUrl = 'https://storage.googleapis.com/zimi-ui-res/images/Zimi_Colored.png';
    return <img src={zimiLogoUrl} width="100" alt="Zimi" />;
};

export default Logo;
