// material-ui
import {
    Card,
    Grid,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';

// project imports

import { IZimiControlpoint } from 'zimi/types';
import ControlpointCard from './ControlPointCard';

// ==============================|| SAMPLE PAGE ||============================== //

interface IProps {
    roomName: string;
    controlPoints: IZimiControlpoint[]
    displayOptions: {
        state: boolean;
        actions: boolean;
        settings: boolean;
    }
}

const ControlpointRoomPage = (props: IProps) => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };


    const roomName = props.roomName;
    const cpList = props.controlPoints;
    const displayOptions = props.displayOptions;

    const cpListForRoom: JSX.Element[] = [];
    cpList.forEach((a) => {
        // if (a.room === roomName) {
            cpListForRoom.push(<ControlpointCard cp={a}
                display={{
                    state: displayOptions.state ,
                    actions: displayOptions.actions,
                    settings: displayOptions.settings,
                }}
                key={'cp_card_' + a.cpId} />);
        // }
    })

    return (
        <Card sx={{
            marginBottom: '20px',
            border: '1px solid',
            borderColor: cardStyle.borderColor,
            background: cardStyle.background,
            padding: '10px'
        }} key={'network-xx_roomcard_' + roomName} >

            <Grid item xs={12}>
                <Typography variant='subtitle1'> {roomName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing} key={'network-xx_roomcard_' + roomName + '_cpcardgrid'}>
                    {cpListForRoom}
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <br />
            </Grid> */}

        </Card>
    );
};

export default ControlpointRoomPage;
