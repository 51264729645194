// material-ui
import {
    Grid
} from '@mui/material';



// project imports
import MainCard from 'ui-component/cards/MainCard';
import React, { useEffect } from 'react';


import ControlpointRoomPage from './ControlPointRoom';
import { useDispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';
import { AddWs, devicesLoad, orgsLoad, wsRemoved } from 'store/slices/zimiBlueprint';
import { devicesToControlPoints } from 'zimi/dataUtils';
import { fi } from 'date-fns/locale';
// import { WsHandler } from 'zimi/messaging/WsHandler';

// ==============================|| SAMPLE PAGE ||============================== //


interface IProps {
    networkid: string;
    displayOptions?: string[];
    groupingOptions?: string;
}
const ControlpointNetwork = (props: IProps) => {

    const [zccId, setZccId] = React.useState<string>();

    const networkid = props.networkid;
    const user = useAuth().getCurrentUser()

    const dispatch = useDispatch();

    useEffect(() => {
        user?.getIdToken()
            .then(jwt => {
                if (jwt && networkid) {
                    // dispatch(devicesLoad(jwt, networkid));
                    // dispatch(orgsLoad(jwt, networkid));
                }
            })

        return () => {
            // wsHandler?.close();
            // if(zccId){                
            // dispatch( wsRemoved(zccId))
            setZccId(undefined)
            // }
            console.log('useEffect cleanup');
            // setWsHandler(undefined);

        }
    }, [networkid]);

    useEffect(() => {
        if (zccId) {
            dispatch(AddWs(zccId))
        }

        return () => {
            if (zccId) {
                dispatch(wsRemoved(zccId))
            }
        }

    }, [zccId])



    const devices = useSelector((state) => state.blueprint.deviceState.devices.filter(dev => dev.organizationId === networkid));
    const network = useSelector((state) => state.blueprint.orgState.orgs.find(org => org.id === networkid));
    // const zccConnected = useSelector((state) => state.blueprint.wss.connectedZCC);

    if (devices) {

        if (zccId === undefined) {
            const zccs = devices.filter(dev => dev.deviceType === 'zcc' && dev.organizationId === networkid);
            zccs.forEach(zcc => {
                // this.state.fcmHandler.subscribeToGateway(zcc.id);
                // const foundZCC = zccConnected.find(zccConnected => { return zcc.id === zccConnected })
                // if (foundZCC) {
                // const ws = new WsHandler(zcc.id)
                setZccId(zcc.id)
                // }
            })
        }
    }


    // const cpList = getControlpoints();
    const cpList = devicesToControlPoints(devices, network)

    const displayOptions = props.displayOptions ? props.displayOptions : [];
    const displayState = displayOptions.find(a => a === 'state') ? true : false;
    const displayActions = displayOptions.find(a => a === 'actions') ? true : false;
    const displaySettings = displayOptions.find(a => a === 'settings') ? true : false;

    const field = props.groupingOptions ? ( props.groupingOptions as 'room' | 'cpType') : 'room' ;
    const fieldSet: Set<string> = new Set();
    cpList.forEach(a => fieldSet.add(a[field]));

    const fieldCPArray: string[] = [];
    fieldSet.forEach(element => {
        fieldCPArray.push(element)
    });

    const roomsComponents = fieldCPArray.map(fieldName => {
        const roomCps = cpList.filter((a) => a[field] === fieldName)
        return <ControlpointRoomPage roomName={fieldName} controlPoints={roomCps}
            displayOptions={{ state: displayState, actions: displayActions, settings: displaySettings }} key={'cp_room_page_' + fieldName} />
    })

    return (
        <MainCard key={'cps_page_' + networkid} title={network?.name} >
            {roomsComponents}
        </MainCard>
    );
};

export default ControlpointNetwork;
