
import { PowermeshProtocolConversions, zimiRegTypes } from '@nilushan/zimi_pm_register_conversions'
import { IDevice, IOrganization } from '../zimi/blueprint'
import { IPowermeshDevice, IPowermeshDeviceGPO, IPowermeshDeviceDimmer, IPowermeshDeviceClayton, IPowermeshDeviceMcCoy, IPowermeshDeviceFan, IPowermeshDeviceGarage, IControlPropsDimmer, IControlPropsFan, IControlPropsGPO, IControlPropsGarage, IControlPropsSwitch, IPowermeshDeviceSenMds, IControlPropsDimmerV2, IControlPropsBlind, IPowermeshDeviceSenMps, IPowermeshDeviceGpoV2, IPowermeshDeviceBlindV2 } from '@nilushan/zimi_pm_register_conversions/lib/powermeshProtocol/types';

export interface IDeviceControlUpdate {
    deviceId: string;
    roomId: number;
    roomName: string | undefined;
    deviceName: string | undefined;
    deviceType: string;
    outletType: 'gposwitch'| 'gposwitch0' | 'gposwitch1' | 'switch' | 'switch0' | 'switch1' | 'switch2' | 'switch3' | 'switch4' | 'dimmer' | 'dimmer0' | 'dimmer1' | 'dimmer2' | 'dimmer3' | 'dimmer4' | 'fan' | 'garagedoor' | 'light' | 'blind'| 'none';
    organizationId: string;
    controlLevel: number | undefined;
    controlState: IControlPropsDimmer | IControlPropsFan | IControlPropsGPO | IControlPropsGarage | IControlPropsSwitch | IControlPropsDimmerV2 | IControlPropsBlind | undefined;
    settingsState: any;
    // controlPoint: {
    //     deviceId: string;
    //     outletNumber: 1 | 2 | 3 | 4 | 5 | 6
    // };
    outletNumber: 1 | 2 | 3 | 4 | 5 | 6;
    controlpointId: string;
    device: IDevice;

}

export interface IGarageSensorStatUpdate {
    deviceId: string;
    timestamp: Date;
    roomId: number;
    roomName: string;
    deviceName: string;
    deviceType: string;
    outletType: string;
    organizationId: string;
    organizationName: string;
    sensorState1: number;
    sensorBattery1: number;
    sensorState2: number;
    sensorBattery2: number;
    garageTemp: number;
    garageHumidity: number;
}

export class RegisterDecoder {

    private conversions: PowermeshProtocolConversions;
    private static instance: RegisterDecoder;
    private constructor() {
        this.conversions = new PowermeshProtocolConversions();
    }


    public static getInstance(): RegisterDecoder {
        if (!this.instance) {
            this.instance = new RegisterDecoder();
        }
        return this.instance;
    }

    public decodeRegisters(device: IDevice): IPowermeshDevice | undefined {

        let deviceTypeReg: zimiRegTypes.DeviceType = 'gpo';
        let deviceTypeAssigned = true;
        switch (device.deviceType) {
            case '2ggpo':
                deviceTypeReg = 'gpo';
                break;
            case '1gdimmer':
                deviceTypeReg = 'dimmer';
                break;
            case '1g3w10ax':
                deviceTypeReg = 'clayton';
                break;
            case 'mccoy':
                deviceTypeReg = 'mccoy';
                break;
            case '1gfan':
                deviceTypeReg = 'fan';
                break;
            case 'ugdc':
                deviceTypeReg = 'ugdc';
                break;
            case 'senmds2':
            case 'senmds4':
            case 'senmds6':
                deviceTypeReg = 'senmds';
                break;
            case 'senmps2':
            case 'senmps4':
            case 'senmps6':
                deviceTypeReg = 'senmps';
                break;
            case 'senpp':
                deviceTypeReg = 'senpp';
                break;
            case 'bc2':
            case 'bc4':
            case 'senbc2':
            case 'senbc4':
                deviceTypeReg = 'bc_v2';
                break;
            default:
                deviceTypeAssigned = false;
        }

        if (deviceTypeAssigned && deviceTypeReg) {
            const regs: zimiRegTypes.IPowermeshDeviceRegisters = {
                deviceType: deviceTypeReg,
                R0: device.R0,
                R1: device.R1,
                R2: device.R2,
                R3: device.R3,
                R4: device.R4,
                R5: device.R5,
                R8: device.R8,
                R9: device.R9,
                R10: device.R10,
                R11: device.R11,
                R12: device.R12,
                R13: device.R13,
                R14: device.R14
            }
            const decodedDevice = this.conversions.decode(regs);
            return decodedDevice;
        } else {
            // log error
            return;
        }

    }

    public decodeRegistersForControlHistory(device: IDevice, rooms: string[]): IDeviceControlUpdate[] | undefined {
        const decodedDevice = this.decodeRegisters(device);
        // let connected = null;
        // if (device.connected) {
        //     connected = device.connected;
        // }

        const genericUpdateEntry: IDeviceControlUpdate = {
            controlpointId: '',
            deviceId: device.id,
            deviceType: decodedDevice?.deviceType ? decodedDevice?.deviceType : 'notfound',
            roomId: -1,
            roomName: '',
            deviceName: '',
            outletType: 'none',
            organizationId: device.organizationId ? device.organizationId : '',
            controlLevel: -1,
            controlState: undefined,
            settingsState: undefined, 
            outletNumber: 1,
            device: device,

        };

        if (decodedDevice) {
            let roomId = -1;

            const deviceUpdateArray: IDeviceControlUpdate[] = [];
            switch (decodedDevice.deviceType) {
                case 'gpo':
                    const decodedDeviceGpoCast = decodedDevice as IPowermeshDeviceGPO;

                    roomId = decodedDeviceGpoCast.miscConfiguration?.roomId !== undefined ? decodedDeviceGpoCast.miscConfiguration?.roomId : -1;
                    genericUpdateEntry.roomId = roomId;
                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                    const l1: IDeviceControlUpdate = {
                        ...genericUpdateEntry, deviceId: device.id + 'L',
                        outletType: 'gposwitch0',
                        deviceName: device.outputName1,
                        controlLevel: decodedDeviceGpoCast.outlets[0]?.controlProps?.isOn ? 100 : 0,
                        controlState: decodedDeviceGpoCast.outlets[0]?.controlProps,
                        settingsState: decodedDeviceGpoCast.outlets[0]?.settingsProps,
                        controlpointId: device.id + '_1',
                        outletNumber: 1
                    }
                    this.addValueIfSet(deviceUpdateArray, l1);

                    const l2: IDeviceControlUpdate = {
                        ...genericUpdateEntry, deviceId: device.id + 'R',
                        outletType: 'gposwitch1',
                        deviceName: device.outputName2,
                        controlLevel: decodedDeviceGpoCast.outlets[1]?.controlProps?.isOn ? 100 : 0,
                        controlState: decodedDeviceGpoCast.outlets[1]?.controlProps,
                        settingsState: decodedDeviceGpoCast.outlets[1]?.settingsProps,
                        controlpointId: device.id + '_2',
                        outletNumber: 2


                    }
                    this.addValueIfSet(deviceUpdateArray, l2);

                    break;

                case 'dimmer':
                    const decodedDeviceDimmerCast = decodedDevice as IPowermeshDeviceDimmer;

                    roomId = decodedDeviceDimmerCast.miscConfiguration?.roomId !== undefined ? decodedDeviceDimmerCast.miscConfiguration?.roomId : -1;
                    genericUpdateEntry.roomId = roomId;
                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);

                    const d: IDeviceControlUpdate = {
                        ...genericUpdateEntry, deviceId: device.id, outletType: 'dimmer', deviceName: device.outputName1 || device.outputName,
                        controlLevel: decodedDeviceDimmerCast.outlets[0]?.controlProps?.currentBrightness,
                        controlState: decodedDeviceDimmerCast.outlets[0]?.controlProps,
                        settingsState: decodedDeviceDimmerCast.outlets[0]?.settingsProps,
                        controlpointId: device.id + '_1',
                        outletNumber: 1

                    }
                    this.addValueIfSet(deviceUpdateArray, d);

                    break;

                case 'clayton':
                    const decodedDeviceClaytonCast = decodedDevice as IPowermeshDeviceClayton;

                    if (decodedDeviceClaytonCast.outlets[0].settingsProps?.master) {
                        const claytonRoomId = decodedDeviceClaytonCast.outlets[0].settingsProps?.master?.roomId;
                        roomId = claytonRoomId ? claytonRoomId : -1;
                        genericUpdateEntry.roomId = roomId;
                        genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                        const c: IDeviceControlUpdate = {
                            ...genericUpdateEntry, deviceId: device.id, outletType: 'switch', deviceName: device.outputName1,
                            controlLevel: decodedDeviceClaytonCast.outlets[0]?.controlProps?.isOn ? 100 : 0,
                            controlState: decodedDeviceClaytonCast.outlets[0]?.controlProps,
                            settingsState: decodedDeviceClaytonCast.outlets[0]?.settingsProps,
                            controlpointId: device.id + '_1',
                            outletNumber: 1

                        }
                        this.addValueIfSet(deviceUpdateArray, c);
                    }
                    break;

                case 'mccoy':
                    const decodedDeviceMccoyCast = decodedDevice as IPowermeshDeviceMcCoy;

                    [
                        { postFix: 'C', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                        { postFix: 'D', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                        { postFix: 'E', index: 2, deviceName: device.outputName3, controlpointIndex: 3 },
                        { postFix: 'G', index: 3, deviceName: device.outputName4, controlpointIndex: 4 },
                    ]
                        .forEach(mccoyOutlet => {

                            if (decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master) {
                                // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                                const switchRoomId = decodedDeviceMccoyCast.miscConfiguration?.roomId !== undefined ? decodedDeviceMccoyCast.miscConfiguration?.roomId : -1;;
                                roomId = switchRoomId ;
                                genericUpdateEntry.roomId = roomId;
                                genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);


                                const mccoyoutletUpdate: IDeviceControlUpdate = {
                                    ...genericUpdateEntry, deviceId: device.id + mccoyOutlet.postFix, outletType: 'switch', deviceName: mccoyOutlet.deviceName,
                                    controlLevel: decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.controlProps?.isOn ? 100 : 0,
                                    controlState: decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.controlProps,
                                    settingsState: decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps,
                                    controlpointId: device.id + '_' + mccoyOutlet.controlpointIndex,
                                    outletNumber: mccoyOutlet.controlpointIndex as 1 | 2 | 3 | 4

                                }
                                this.addValueIfSet(deviceUpdateArray, mccoyoutletUpdate);
                            }

                        })

                    break;

                case 'fan':
                    const decodedDeviceFanCast = decodedDevice as IPowermeshDeviceFan;


                    const fanswitchRoomId = decodedDeviceFanCast.outlets[0].settingsProps?.master?.roomId;
                    roomId = fanswitchRoomId ? fanswitchRoomId : -1;
                    genericUpdateEntry.roomId = roomId;
                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                    const fanSwitchUpdate: IDeviceControlUpdate = {
                        ...genericUpdateEntry, deviceId: device.id + 'S', outletType: 'switch', deviceName: device.outputName1,
                        controlLevel: decodedDeviceFanCast.outlets[0]?.controlProps?.isOn ? 100 : 0,
                        controlState: decodedDeviceFanCast.outlets[0]?.controlProps,
                        settingsState: decodedDeviceFanCast.outlets[0]?.settingsProps,
                        controlpointId: device.id + '_' + 1,
                        outletNumber: 1


                    }
                    this.addValueIfSet(deviceUpdateArray, fanSwitchUpdate);


                    const fanRoomId = decodedDeviceFanCast.outlets[1].settingsProps?.roomId;
                    roomId = fanRoomId ? fanRoomId : -1;
                    genericUpdateEntry.roomId = roomId;
                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                    const fanUpdate: IDeviceControlUpdate = {
                        ...genericUpdateEntry, deviceId: device.id + 'F', outletType: 'fan', deviceName: device.outputName2,
                        controlLevel: decodedDeviceFanCast.outlets[1]?.controlProps?.fanSpeed,
                        controlState: decodedDeviceFanCast.outlets[1]?.controlProps,
                        settingsState: decodedDeviceFanCast.outlets[1]?.settingsProps,
                        controlpointId: device.id + '_' + 2,
                        outletNumber: 2


                    }
                    this.addValueIfSet(deviceUpdateArray, fanUpdate);

                    break;

                case 'ugdc':
                    const decodedDeviceGarageCast = decodedDevice as IPowermeshDeviceGarage;

                    [{ postFix: 'A', index: 0, deviceName: device.outputName1, cpIndex: 1 }, { postFix: 'B', index: 1, deviceName: device.outputName2, cpIndex: 2 }]
                        .forEach(garageOutlet => {

                            // updates from R8
                            // if (decodedDeviceGarageCast.outlets[garageOutlet.index]?.settingsProps?.enabled) {
                            //     const garageRoomId = decodedDeviceGarageCast.miscConfiguration?.roomId ? decodedDeviceGarageCast.miscConfiguration.roomId : -1
                            //     roomId = garageRoomId ? garageRoomId : -1;
                            //     genericUpdateEntry.roomId = roomId;
                            //     genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                            //     const garageUpdate = {
                            //         ...genericUpdateEntry, deviceId: device.id + garageOutlet.postFix, outletType: 'garagedoor', deviceName: garageOutlet.deviceName,
                            //         controlLevel: decodedDeviceGarageCast.outlets[garageOutlet.index]?.controlProps?.door?.currentPosition,
                            //         controlState: decodedDeviceGarageCast.outlets[garageOutlet.index]?.controlProps
                            //     }
                            //     this.addValueIfSet(deviceUpdateArray, garageUpdate);
                            // }

                            // updates from R13, R14
                            if (decodedDeviceGarageCast.outlets[garageOutlet.index]?.settingsProps?.enabled) {
                                const garageRoomId = decodedDeviceGarageCast.miscConfiguration?.roomId !== undefined? decodedDeviceGarageCast.miscConfiguration.roomId : -1
                                roomId = garageRoomId ;
                                genericUpdateEntry.roomId = roomId;
                                genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);
                                const garageUpdate: IDeviceControlUpdate = {
                                    ...genericUpdateEntry, deviceId: device.id + garageOutlet.postFix, outletType: 'garagedoor', deviceName: garageOutlet.deviceName,
                                    controlLevel: decodedDeviceGarageCast.outlets[garageOutlet.index]?.controlProps?.door?.currentPosition,
                                    controlState: decodedDeviceGarageCast.outlets[garageOutlet.index]?.controlProps ? decodedDeviceGarageCast.outlets[garageOutlet.index]?.controlProps : {},
                                    settingsState: decodedDeviceGarageCast.outlets[garageOutlet.index]?.settingsProps,
                                    controlpointId: device.id + '_' + garageOutlet.cpIndex,
                                    outletNumber: garageOutlet.cpIndex as 1 | 2


                                }
                                this.addValueIfSet(deviceUpdateArray, garageUpdate);
                            }
                        })

                    break;

                case 'senmds':
                    {
                        const decodedDeviceSenMdsCast = decodedDevice as IPowermeshDeviceSenMds;

                        [
                            { postFix: '_0', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                            { postFix: '_1', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                            { postFix: '_2', index: 2, deviceName: device.outputName3, controlpointIndex: 3 },
                            { postFix: '_3', index: 3, deviceName: device.outputName4, controlpointIndex: 4 },
                            { postFix: '_4', index: 4, deviceName: device.outputName5, controlpointIndex: 5 },
                            { postFix: '_5', index: 5, deviceName: device.outputName6, controlpointIndex: 6 },

                        ]
                            .forEach(senOutlet => {

                                const outlet = decodedDeviceSenMdsCast.outlets[senOutlet.index];
                                if (outlet && (outlet.settingsProps?.master || outlet.settingsProps?.masterDimmer)) {
                                    // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                                    const switchRoomId = decodedDeviceSenMdsCast.miscConfiguration?.roomId !== undefined? decodedDeviceSenMdsCast.miscConfiguration?.roomId : -1;;
                                    roomId = switchRoomId ;
                                    genericUpdateEntry.roomId = roomId;
                                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);


                                    if (outlet.controlProps?.light) {

                                        const senmdsLight: IDeviceControlUpdate = {
                                            ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'light', deviceName: senOutlet.deviceName,
                                            controlLevel: outlet.controlProps.light.isOn ? 100 : 0,
                                            controlState: outlet.controlProps.light,
                                            controlpointId: device.id + '_' + senOutlet.controlpointIndex,
                                            outletNumber: senOutlet.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                        }
                                        this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                    }

                                    if (outlet.controlProps?.switch) {

                                        const senmdsLight: IDeviceControlUpdate = {
                                            ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'switch', deviceName: senOutlet.deviceName,
                                            controlLevel: outlet.controlProps.switch.isOn ? 100 : 0,
                                            controlState: outlet.controlProps.switch,
                                            controlpointId: device.id + '_' + senOutlet.controlpointIndex,
                                            outletNumber: senOutlet.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                        }
                                        this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                    }

                                    if (outlet.controlProps?.dimmer_v2) {

                                        const senmdsLight: IDeviceControlUpdate = {
                                            ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'dimmer', deviceName: senOutlet.deviceName,
                                            controlLevel: outlet.controlProps.dimmer_v2.currentBrightness.brightness,
                                            controlState: outlet.controlProps.dimmer_v2,
                                            controlpointId: device.id + '_' + senOutlet.controlpointIndex,
                                            outletNumber: senOutlet.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                        }
                                        this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                    }


                                }

                            })
                    }
                    break;

                case 'senmps':
                    {
                        const decodedDeviceSenMpsCast = decodedDevice as IPowermeshDeviceSenMps;

                        [
                            { postFix: '_0', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                            { postFix: '_1', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                            { postFix: '_2', index: 2, deviceName: device.outputName3, controlpointIndex: 3 },
                            { postFix: '_3', index: 3, deviceName: device.outputName4, controlpointIndex: 4 },
                            { postFix: '_4', index: 4, deviceName: device.outputName4, controlpointIndex: 5 },
                            { postFix: '_5', index: 5, deviceName: device.outputName4, controlpointIndex: 6 },

                        ]
                            .forEach(senOutlet => {

                                const outlet = decodedDeviceSenMpsCast.outlets[senOutlet.index];
                                if (outlet && outlet.settingsProps?.master) {
                                    // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                                    const switchRoomId = decodedDeviceSenMpsCast.miscConfiguration?.roomId!== undefined ? decodedDeviceSenMpsCast.miscConfiguration?.roomId : -1;;
                                    roomId = switchRoomId ;
                                    genericUpdateEntry.roomId = roomId;
                                    genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);

                                    if (outlet.controlProps) {

                                        const senmdsLight: IDeviceControlUpdate = {
                                            ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'switch', deviceName: senOutlet.deviceName,
                                            controlLevel: outlet.controlProps.isOn ? 100 : 0,
                                            controlState: outlet.controlProps,
                                            controlpointId: device.id + '_' + senOutlet.controlpointIndex,
                                            outletNumber: senOutlet.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                        }
                                        this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                    }
                                }
                            })
                    }
                    break;


                case 'senpp':
                case 'gpo_v2':
                    {
                        const decodedDeviceGpoV2Cast = decodedDevice as IPowermeshDeviceGpoV2;

                        [
                            { postFix: '_0', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                            { postFix: '_1', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                        ]
                            .forEach(senOutlet => {

                                const outlet = decodedDeviceGpoV2Cast.outlets[senOutlet.index];
                                // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                                const switchRoomId = decodedDeviceGpoV2Cast.miscConfiguration?.roomId !== undefined? decodedDeviceGpoV2Cast.miscConfiguration?.roomId : -1;;
                                roomId = switchRoomId ;
                                genericUpdateEntry.roomId = roomId;
                                genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);

                                if (outlet.controlProps) {

                                    const senmdsLight: IDeviceControlUpdate = {
                                        ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'gposwitch', deviceName: senOutlet.deviceName,
                                        controlLevel: outlet.controlProps.isOn ? 100 : 0,
                                        controlState: outlet.controlProps,
                                        controlpointId: device.id + '_' + senOutlet.controlpointIndex,
                                        outletNumber: senOutlet.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                    }
                                    this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                }

                            })
                    }
                    break;

                case 'senbc':
                case 'bc':
                case 'bc_v2':
                    {
                        const decodedDeviceBlind = decodedDevice as IPowermeshDeviceBlindV2;

                        const channelDetails = [
                            { postFix: '_0', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                            { postFix: '_1', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                        ];

                        [
                            { outletNumber: 0 },
                            { outletNumber: 1 },
                            { outletNumber: 2 },
                            { outletNumber: 3 },
                        ].forEach(senOutlet => {

                            const outlet = decodedDeviceBlind.outlets[senOutlet.outletNumber];
                            // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                            const switchRoomId = decodedDeviceBlind.miscConfiguration?.roomId !== undefined? decodedDeviceBlind.miscConfiguration?.roomId : -1;;
                            roomId = switchRoomId ;
                            genericUpdateEntry.roomId = roomId;
                            genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);

                            if (outlet && outlet.controlProps && outlet.settingsProps) {

                                if (outlet.settingsProps.blind?.type.open) {

                                    const channelNumber = outlet.settingsProps.blind?.type.open?.channelNumber;

                                    if (channelNumber === 0 || channelNumber === 1) {

                                        const channelDetail = channelDetails[channelNumber];

                                        const senmdsLight: IDeviceControlUpdate = {
                                            ...genericUpdateEntry, deviceId: device.id + channelDetail.postFix, outletType: 'blind', deviceName: channelDetail.deviceName,
                                            controlLevel: outlet.controlProps.currentPosition.position,
                                            // controlLevel: outlet.controlProps.state === 'open' ? 1 : (outlet.controlProps.state === 'close' ? -1 : 0),
                                            controlState: outlet.controlProps,
                                            controlpointId: device.id + '_' + channelDetail.controlpointIndex,
                                            outletNumber: channelDetail.controlpointIndex as 1 | 2 | 3 | 4 | 5 | 6

                                        }
                                        this.addValueIfSet(deviceUpdateArray, senmdsLight);
                                    }
                                }
                            }

                        })
                    }
                    break;

                // case 'pmmds':
                //     {
                //         const decodedDevicePmMdsCast = decodedDevice as IPowermeshDevicePmMds;

                //         [
                //             { postFix: '_0', index: 0, deviceName: device.outputName1, controlpointIndex: 1 },
                //             { postFix: '_1', index: 1, deviceName: device.outputName2, controlpointIndex: 2 },
                //             { postFix: '_2', index: 2, deviceName: device.outputName3, controlpointIndex: 3 },
                //             { postFix: '_3', index: 3, deviceName: device.outputName4, controlpointIndex: 4 },
                //             // { postFix: '_4', index: 4, deviceName: device.outputName4, controlpointIndex: 5 },
                //             // { postFix: '_5', index: 5, deviceName: device.outputName4, controlpointIndex: 6 },

                //         ]
                //             .forEach(senOutlet => {

                //                 const outlet = decodedDevicePmMdsCast.outlets[senOutlet.index];
                //                 if (outlet && (outlet.settingsProps?.master || outlet.settingsProps?.masterDimmer)) {
                //                     // const switchRoomId = decodedDeviceMccoyCast.outlets[mccoyOutlet.index]?.settingsProps?.master?.roomId;
                //                     const switchRoomId = decodedDevicePmMdsCast.miscConfiguration?.roomId ? decodedDevicePmMdsCast.miscConfiguration?.roomId : -1;;
                //                     roomId = switchRoomId ? switchRoomId : -1;
                //                     genericUpdateEntry.roomId = roomId;
                //                     genericUpdateEntry.roomName = this.getRoomNameForId(rooms, roomId);


                //                     if (outlet.controlProps?.light) {

                //                         const senmdsLight: IDeviceControlUpdate = {
                //                             ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'light', deviceName: senOutlet.deviceName,
                //                             controlLevel: outlet.controlProps.light.isOn ? 100 : 0,
                //                             controlState: outlet.controlProps.light,
                //                             controlpointId: device.id + '_' + senOutlet.controlpointIndex
                //                         }
                //                         this.addValueIfSet(deviceUpdateArray, senmdsLight);
                //                     }

                //                     if (outlet.controlProps?.switch) {

                //                         const senmdsLight: IDeviceControlUpdate = {
                //                             ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'switch', deviceName: senOutlet.deviceName,
                //                             controlLevel: outlet.controlProps.switch.isOn ? 100 : 0,
                //                             controlState: outlet.controlProps.switch,
                //                             controlpointId: device.id + '_' + senOutlet.controlpointIndex
                //                         }
                //                         this.addValueIfSet(deviceUpdateArray, senmdsLight);
                //                     }

                //                     if (outlet.controlProps?.dimmer_v2) {

                //                         const senmdsLight: IDeviceControlUpdate = {
                //                             ...genericUpdateEntry, deviceId: device.id + senOutlet.postFix, outletType: 'dimmer', deviceName: senOutlet.deviceName,
                //                             controlLevel: outlet.controlProps.dimmer_v2.currentBrightness.brightness,
                //                             controlState: outlet.controlProps.dimmer_v2,
                //                             controlpointId: device.id + '_' + senOutlet.controlpointIndex
                //                         }
                //                         this.addValueIfSet(deviceUpdateArray, senmdsLight);
                //                     }
                //                 }
                //             })
                //     }
                //     break;

            }
            return deviceUpdateArray;


        } else {
            return;
        }
    }

    public decodeRegistersForGarageSensorStatHistory(device: IDevice, timestamp: Date, org: IOrganization): IGarageSensorStatUpdate | undefined {
        const decodedDevice = this.decodeRegisters(device);

        if (decodedDevice) {

            switch (decodedDevice.deviceType) {

                case 'ugdc':
                    const decodedDeviceGarageCast = decodedDevice as IPowermeshDeviceGarage;

                    if (decodedDeviceGarageCast.outlets[0]?.controlProps?.sensor &&
                        decodedDeviceGarageCast.outlets[1]?.controlProps?.sensor &&
                        decodedDeviceGarageCast.controls
                    ) {
                        const garageRoomId = decodedDeviceGarageCast.miscConfiguration?.roomId !== undefined ? decodedDeviceGarageCast.miscConfiguration.roomId : -1
                        const roomName = this.getRoomNameForId(org.roomNames ? org.roomNames.split(',') : [], garageRoomId);
                        const garageSensorStatUpdate: IGarageSensorStatUpdate = {
                            deviceId: device.id,
                            deviceType: decodedDevice?.deviceType ? decodedDevice?.deviceType : 'notfound',
                            timestamp,
                            roomId: garageRoomId,
                            roomName: roomName ? roomName : '',
                            deviceName: device.outputName1 + ',' + device.outputName2,
                            outletType: 'ugds',
                            organizationId: device.organizationId ? device.organizationId : '',
                            organizationName: org.defaultAlias,
                            sensorBattery1: decodedDeviceGarageCast.outlets[0].controlProps.sensor.sensorBattery,
                            sensorState1: decodedDeviceGarageCast.outlets[0].controlProps.sensor.sensorState,
                            sensorBattery2: decodedDeviceGarageCast.outlets[1].controlProps.sensor.sensorBattery,
                            sensorState2: decodedDeviceGarageCast.outlets[0].controlProps.sensor.sensorState,
                            garageHumidity: decodedDeviceGarageCast.controls.garageHumidity,
                            garageTemp: decodedDeviceGarageCast.controls.garageTemperature,
                        }
                        return garageSensorStatUpdate;
                    }
                    break;
            }

        } else {
            return;
        }
    }

    private fanSpeedToSetting(speed: number): string {
        let speedValue = '';
        // if (speed > 86) {
        //     speedValue = 'S8';
        // } else
        if (speed > 78) {
            speedValue = 'S7';
        } else if (speed > 64) {
            speedValue = 'S6';
        } else if (speed > 50) {
            speedValue = 'S5';
        } else if (speed > 35) {
            speedValue = 'S4';
        } else if (speed > 21) {
            speedValue = 'S3';
        } else if (speed > 7) {
            speedValue = 'S2';
        } else if (speed > 0) {
            speedValue = 'S1';
        } else {
            speedValue = 'Off';
        }
        return speedValue;
    }


    private addValueIfSet(array: IDeviceControlUpdate[], element: IDeviceControlUpdate) {
        if ((element.controlState !== undefined && element.controlState !== null && element.controlLevel !== null && element.controlLevel !== undefined)) {
            array.push(element);
        }
    }

    private getRoomNameForId(rooms: string[], roomId: number): string | undefined {
        if (roomId !== undefined && roomId > -1 && rooms.length > roomId) {
            return rooms[roomId];
        } else {
            return;
        }
    }
}