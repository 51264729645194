// material-ui
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { useDispatch, useSelector } from 'store';



// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { getNetworks } from 'zimi/data';
import { IZimiNetwork } from 'zimi/types';
import { useEffect, useState } from 'react';
import { devicesLoad, orgsLoad } from 'store/slices/zimiBlueprint';
import useAuth from 'hooks/useAuth';
import { convertOrgs } from 'zimi/dataUtils';


import { IconBrandChrome, IconHelp, IconSitemap, IconBoxModel, IconCpu, IconCpu2 } from '@tabler/icons';
import NetworkSelector from './NetworkSelector';
import { IDevice } from 'zimi/blueprint';
import NetworkPowerUsageChart from './NetworkPowerUsageChart';
// import NetworkPowerUsageChart from './NetworkPowerUsageChart';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBoxModel,
    IconCpu,
    IconCpu2
};

// ==============================|| SAMPLE PAGE ||============================== //

const PowerUsagePage = () => {
    const dispatch = useDispatch();

    const user = useAuth().getCurrentUser()
    const theme = useTheme();

    const [selectedNetwork, setSelectedNetwork] = useState<string>('')

    const [devicesInNetwork, setDevicesInNetwork] = useState<IDevice[]>([]);

    useEffect(() => {
        user?.getIdToken()
            .then(jwt => {
                if (jwt) {
                    dispatch(orgsLoad(jwt));
                }
            })
    }, []);



    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };


    const { orgs } = useSelector((state) => state.blueprint.orgState);

    const devicesFiltered = useSelector((state) => {
        const devicesFiltered = state.blueprint.deviceState.devices.filter((dev => dev.organizationId === selectedNetwork))
        return devicesFiltered
        // setDevicesInNetwork(devicesFiltered)
    })



    useEffect(() => {
        if (orgs.length > 0) {
            // setSelectedNetwork(orgs[0].id)
        }
    }, orgs)

    const loadDevicesOfOrg = (orgId: string) => {
        user?.getIdToken()
            .then(jwt => {
                dispatch(devicesLoad(jwt, orgId))
            })
    }
    const networksReturned = convertOrgs(orgs);
    const orgObj = orgs.find(org => org.id === selectedNetwork)

    return (
        <>
            <MainCard>
                <NetworkSelector networks={orgs} selectedNetwork={selectedNetwork} onNetworksSelectedChange={(options => {
                    setSelectedNetwork(options);
                    loadDevicesOfOrg(options)

                })} />

            </MainCard>

            <MainCard>

                <Grid container>

                    <Grid item xs={12}>
                        <NetworkPowerUsageChart org={orgObj} devices={devicesFiltered} />
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default PowerUsagePage;
