
import { Grid, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker'

interface IOwnProps {
    startDate: Date | null;
    endDate: Date | null;

    onDateChanged: (startDate: Date | null, endDate: Date | null) => void
    onResolutionChanged: (resolution: string) => void
}



const DateRangePicker = (props: IOwnProps) => {
    // const [startDate, setStartDate] = useState<any>( props.startDate || new Date("2021/02/01"));
    // const [endDate, setEndDate] = useState<any>(props.endDate || new Date("2021/10/31"));
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([props.startDate, props.endDate] || [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), new Date(Date.now())]);
    const [resolution, setResolution] = useState('day')

    return (

        <Grid container>
            <Grid item md={3}>
                <div> Date Range </div>
                <DatePicker
                    selected={dateRange[0]}
                    onChange={(date: [Date, Date]) => {
                        //  console.log(JSON.stringify(date)   );
                        if (date && Array.isArray(date)) {
                            setDateRange(date);
                            props.onDateChanged(date[0], date[1])
                        }
                    }
                    }
                    selectsRange
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    monthsShown={2}
                />
            </Grid>


            <Grid item md={3}>
                <div>
                    Resolution
                </div>

                <Select aria-label="Default select example" defaultValue={resolution} style={{ width: '200px' }}
                    onChange={(e) => {
                        // console.log(JSON.stringify(e.target.value))
                        setResolution(e.target.value);
                        props.onResolutionChanged(e.target.value)

                    }}>
                    {/* <option>Select an option</option> */}
                    <MenuItem value="minute">Minutely</MenuItem>
                    <MenuItem value="hour">Hourly</MenuItem>
                    <MenuItem value="day">Daily</MenuItem>
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>

                </Select>
            </Grid>
        </Grid>

    );
};

export default DateRangePicker;