
import * as requestPromise from 'request-promise'
import { appConfig } from '../../config';


const HOST =  appConfig.cpActionsConfig.baseUrl;
// const PORT = process.env.PORT || 80;
const BASEURL = 'https://' + HOST  //+ (PORT !== '80' ? ':' + PORT : '');

export const applyAction = (jwt: string, requestBody: object): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json' },
            url: BASEURL + '/api/zimi',
            body: JSON.stringify(requestBody),
            resolveWithFullResponse: true,
          
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};
