import { useEffect, useState } from "react";

import { IDevice, IOrganization } from "zimi/blueprint";
import { getPowerUsages, IPowerUsageEntry } from "zimi/requests/zimiserverRequests";
import moment from "moment";

import { ResponsivePie } from '@nivo/pie'
import * as c3 from 'c3'

import 'c3/c3.css';
import 'react-datepicker/dist/react-datepicker.css'

import { INetworks, PowerTree } from "../../libs/PowerTree";
import DateRangePicker from "ui-component/DateRangePicker";
import { Alert, Button, Grid, Snackbar } from "@mui/material";
import useAuth from "hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";


interface IChartData {
    data: {
        id: string,
        cpId: string,
        data: { x: Date, y: number }[]
    }[]
}

interface IChartDataInt {
    data: {
        id: string,
        cpId: string,
        data: { x: Date, y: number }[],
        total: number;
    }[]
}

interface IBarChartData {
    data: {
        timestamp: Date;
        [key: string]: any;
    }[];
}

interface ISelectedNodes {
    nodes: ISelectedNode[];
}
interface ISelectedNode {
    networkId: string;
    roomNames: Set<string>;
    controlPointIds: Set<string>;
}

interface IClickedNode {
    networkId: string;
    roomNames: string;
    controlPointIds?: string;
}

interface IProps extends IOwnProps, ReduxStateProps {

}

interface IOwnProps {
    // powerData: IPowerUsageEntry[];
    devices: IDevice[];
    org?: IOrganization;
}

interface ReduxStateProps {

}

interface MatchProps {
    orgId: string;
}

// interface IState {
//     // powerData: IPowerUsageEntry[];
//     // convertedData: IChartData;
//     displayedData: IChartData;
//     displayedBarChartData: IBarChartData;
//     // hiddenIdSet: Set<string | number>;

//     startDate: Date | null;
//     endDate: Date | null;
//     resolution: string;

//     deviceTree?: INetworks;

//     selectedNodes: ISelectedNodes;


// }
let powerTree: PowerTree = new PowerTree();


const NetworkPowerUsageChart = (props: IProps) => {

    // private fcmHandler: FcmHandler;

    const [powerData, setPowerData] = useState<IPowerUsageEntry[]>([]);
    const [displayedData, setDisplayedData] = useState<IChartData>({ data: [] });
    const [displayedBarChartData, setDisplayedBarChartData] = useState<IBarChartData>({ data: [] });
    const [startDate, setStartDate] = useState<Date>(new Date(Date.now() - 31 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState<Date>(new Date(Date.now()));
    const [resolution, setResolution] = useState<string>('day');

    const [deviceTree, setDeviceTree] = useState<INetworks | undefined>(undefined)
    const [selectedNodes, setSelectedNodes] = useState<ISelectedNodes>({ nodes: [] });
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const user = useAuth().getCurrentUser();

    useEffect(() => {
        setDeviceTree(new PowerTree().getPowerTree());
        setPowerData([]);
        setDisplayedData({ data: [] });
        setDisplayedBarChartData({ data: [] })
        setDataLoading(false);
        setSelectedNodes({ nodes: [] })
        powerTree = new PowerTree();
        // renderChart()

    }, [props.org?.id])


    const renderChart = () => {


        const barChartKeysMap: Set<string> = new Set();
        displayedBarChartData.data.forEach(times => {
            Object.keys(times).forEach(id => {
                if (id !== 'timestamp' && id !== 'timestampDate') {
                    barChartKeysMap.add(id)
                }
            })
        })

        const barChartKeys: string[] = []

        barChartKeysMap.forEach(key => {
            barChartKeys.push(key);
        })

        // let data = this.state.powerData.map(entry => {
        //     return {
        //         date: moment(entry.timestamp).format('yyyy-M-DD'),
        //         device: entry.deviceId,
        //         usage:  parseInt(entry.usage+'',10)
        //     }
        // });

        let data = displayedBarChartData.data;
        c3.generate({
            bindto: "#c3chart",
            data: {
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                keys: {
                    x: 'timestampDate',
                    value: barChartKeys,
                },
                // columns: [
                //     ['data1', -30, 200, 200, 400, -150, 250],
                //     ['data2', 130, 100, -100, 200, -150, 50],
                //     ['data3', -230, 200, 200, -300, 250, 250]
                // ],
                json: data,
                type: 'bar',
                groups: [barChartKeys]

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d %H:%M'
                    }
                }
            },
            grid: {
                y: {
                    lines: [{ value: 0 }]
                }
            }
        });

        c3.generate({
            bindto: "#c3chartline",
            data: {
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                keys: {
                    x: 'timestampDate',
                    value: barChartKeys,
                },
                // columns: [
                //     ['data1', -30, 200, 200, 400, -150, 250],
                //     ['data2', 130, 100, -100, 200, -150, 50],
                //     ['data3', -230, 200, 200, -300, 250, 250]
                // ],
                json: data,
                type: 'line',
                groups: [barChartKeys]

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d %H:%M'
                    }
                }
            },
            grid: {
                y: {
                    lines: [{ value: 0 }]
                }
            }
        });
    }




    const powerTreeDisplay = () => {

        const networksDiv: any = [];
        deviceTree?.networks.forEach(network => {


            const roomDivs: any[] = [];
            network.rooms.forEach((room, key) => {

                const deviceDivs: any[] = [];
                room.devices.forEach(device => {
                    const deviceDiv = <>
                        <>
                            <div> Device Id: {device.id}</div>
                            <div> Device Name: {device.name}</div>
                            <div> Device Type: {device.deviceType}</div>
                            <div> Device Usage : {device.usageTotal}</div>

                        </>
                    </>
                    if (device.usageTotal !== 0) {
                        deviceDivs.push(deviceDiv);
                        // deviceDivs.push(<div style={{ height: '20px' }}><Col><span>   </span></Col></Row>)
                    }
                })

                // const roomDiv = <>
                //     < md={6}>
                //         <Row>
                //             <Col>
                //                 <div> Room Name: {key}</div>
                //                 <div> Total Usage : {room.usageTotal}</div>
                //             </Col>
                //         </Row>
                //         <Row>
                //             <Col md={12}>
                //                 {this.getPieChatForRoom(network.id, key)}
                //             </Col>
                //         </Row>
                //     </Col>
                //     <Col md={6}>
                //         {deviceDivs}
                //     </Col>
                // </Row>

                // if (room.usageTotal !== 0) {
                //     roomDivs.push(roomDiv)
                // }
            })


            // const networkDiv =
            //     <Row>
            //         <Col md={5}>
            //             <Row> Network Id: {network.id} </Row>
            //             <Row> Network Name: {network.name} </Row>
            //             <Row> Power Usage: {network.usageTotal} </Row>

            //             <Row>
            //                 <Col>
            //                     {this.getPieChatForNetwork(network.id)}
            //                 </Col>
            //             </Row>

            //         </Col>
            //         <Col md={7}> {roomDivs}</Col>

            //     </Row>

            // networksDiv.push(networkDiv)

        })
        return <div> {networksDiv}</div>
    }

    function getPieChatForNetwork(networkId: string) {
        const data = getPieChartDataForNetwork(networkId);
        if (data) {
            return (
                <div style={{ height: '500px', width: '800px' }}>

                    <ResponsivePie
                        data={data}
                        margin={{ top: 40, right: 100, bottom: 80, left: 100 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabelsSkipAngle={1}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        sortByValue={true}

                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ],
                                toggleSerie: true
                            }
                        ]}
                        onClick={(e) => { selectDisplayNodes({ networkId, roomNames: e.label.toString() }) }}
                    />
                </div>

            )
        }

    }

    function getPieChatForRoom(networkId: string, roomName: string) {
        const data = getPieChartDataForNetworkRoom(networkId, roomName);
        if (data) {
            return (
                <div style={{ height: '500px' }}>
                    {/* 
                    <ResponsivePie
                        data={data}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabel={function (e) { return e.label + '' }}
                        // arcLabel={ (e) => e.label+''}

                        arcLinkLabelsSkipAngle={1}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        sortByValue={true}
                        tooltip={({ datum: { id, label, value, color } }) => (
                            <div
                                style={{
                                    padding: 5,
                                    // color,
                                    background: 'white',
                                    boxShadow: 'inherit',

                                }}
                            >
                                {label}:
                                <strong>
                                    {value}
                                </strong>
                            </div>
                        )}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ],
                                toggleSerie: true
                            }
                        ]}
                    /> */}
                </div>
            )
        }

    }

    function getPieChartDataForNetwork(networkId: string) {
        const networkUsageDetails = powerTree.getPowerTree().networks.get(networkId);
        if (networkUsageDetails) {
            const roomsData: { id: string, label: string, value: number }[] = [];
            networkUsageDetails.rooms.forEach((roomContents, roomName) => {
                if (roomContents.usageTotal > 0) {

                    roomsData.push({
                        id: roomName, label: roomName, value: roomContents.usageTotal
                    })
                }
            })
            return roomsData
        }
    }

    function getPieChartDataForNetworkRoom(networkId: string, roomName: string) {
        const networkUsageDetails = powerTree.getPowerTree().networks.get(networkId);
        if (networkUsageDetails) {

            const room = networkUsageDetails.rooms.get(roomName);

            const pieChartData: { id: string, label: string, value: number }[] = [];
            room?.devices.forEach((devContents, key) => {
                pieChartData.push({
                    id: key, label: devContents.name, value: devContents.usageTotal
                })
            })
            return pieChartData;
        }
    }

    function selectDisplayNodes(clickedNode: IClickedNode) {
        const selectedNodeCheck = selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId);
        const updatedSelectedNodes: ISelectedNodes = { nodes: selectedNodes.nodes };

        if (!selectedNodeCheck) {
            const roomNameSet = new Set<string>();
            const controlPointIdSet = new Set<string>();

            updatedSelectedNodes.nodes.push(
                {
                    networkId: clickedNode.networkId,
                    roomNames: roomNameSet,
                    controlPointIds: controlPointIdSet
                }
            )
        }
        const selectedNode = updatedSelectedNodes.nodes.find(node => node.networkId === clickedNode.networkId);

        selectedNode?.roomNames.clear();
        const isRoomSelected = selectedNode?.roomNames.has(clickedNode.roomNames);
        if (isRoomSelected) {
            selectedNode?.roomNames.delete(clickedNode.roomNames);
        } else {
            selectedNode?.roomNames.add(clickedNode.roomNames);
        }

        setSelectedNodes(updatedSelectedNodes);

        // setSelectedNodes( {nodes: selectedNodes.nodes})
        filterDisplayData();
        renderChart()
    }

    function toggleDisplayNodes(clickedNode: IClickedNode) {
        const selectedNodeCheck = selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId)
        if (!selectedNodeCheck) {
            const roomNameSet = new Set<string>();
            const controlPointIdSet = new Set<string>();
            selectedNodes.nodes.push(
                {
                    networkId: clickedNode.networkId,
                    roomNames: roomNameSet,
                    controlPointIds: controlPointIdSet
                }
            )
        }
        const selectedNode = selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId);

        const isRoomSelected = selectedNode?.roomNames.has(clickedNode.roomNames);
        if (isRoomSelected) {
            selectedNode?.roomNames.delete(clickedNode.roomNames);
        } else {
            selectedNode?.roomNames.add(clickedNode.roomNames);
        }

        filterDisplayData();
    }

    function filterDisplayData() {

        const convertedData = convertData2(powerData, props.devices).data;


        // convertedData: { data: convertedData },
        setDisplayedData({ data: convertedData });
        setDisplayedBarChartData({ data: convertDataToBarChartFormat(powerData, props.devices).data });


    }

    function isControlPointSelected(controlpointId: string) {

        if (powerTree.getPowerTree().devices.size > 0) {

            const devTreeCP = powerTree.getPowerTree().devices.get(controlpointId);
            const orgIdOfCp = devTreeCP?.organizationId;
            if (orgIdOfCp && devTreeCP) {
                const selectedOrg = selectedNodes.nodes.find(node => node.networkId === orgIdOfCp);
                if (selectedOrg) {

                    return selectedOrg.roomNames.has(devTreeCP.roomName)
                } else {
                    return true;
                }
            }
            else {
                return false;
            }
        } else {
            return true;
        }
    }


    // toggleIdVisibility(id: string | number) {
    //     const hasId = this.state.hiddenIdSet.has(id);
    //     if (hasId) {
    //         this.state.hiddenIdSet.delete(id);
    //     } else {
    //         this.state.hiddenIdSet.add(id)
    //     }

    //     this.updateDataUsingHiddenIds();
    // }

    // updateDataUsingHiddenIds() {
    //     const newDisplayState: IChartData = { data: [] };

    //     this.state.convertedData.data.forEach(dataCollection => {
    //         if (this.state.hiddenIdSet.has(dataCollection.id) === false) {
    //             newDisplayState.data.push(dataCollection)
    //         } else {
    //             newDisplayState.data.push({ id: dataCollection.id, data: [] })
    //         }
    //     })
    //     this.setState({ displayedData: newDisplayState })


    // }


    function loadPowerData() {

        let deviceIdsForPower: string[] = [];
        props.devices.forEach(dev => {
            getLegacyControlpointIdsForDevice(dev)
                .forEach(cpId => {
                    deviceIdsForPower.push(cpId);
                })
        })
        user?.getIdToken()
            .then((jwt: string) => {
                if (deviceIdsForPower.length > 0) {
                    setDataLoading(true);
                    getPowerUsages(jwt, deviceIdsForPower, startDate, endDate, resolution)
                        .then(ret => {

                            const convertedData = convertData2(ret, props.devices).data;

                            if (props.org && props.devices) {
                                powerTree.createPowerTree(props.devices, [props.org]);
                                convertedData.forEach(entries => {
                                    if (entries.data) {

                                        powerTree.setPowerUsage(
                                            entries.cpId,
                                            entries.data.map(entry => { return { timestamp: entry.x, usage: entry.y } }))
                                    }
                                })

                                const tree = powerTree.getPowerTree();

                                // this.setState(
                                //     {
                                //         deviceTree: tree,
                                //         powerData: ret,
                                //         // convertedData: { data: convertedData },
                                //         displayedData: { data: convertedData },
                                //         displayedBarChartData: { data: this.convertDataToBarChartFormat(ret, this.props.devices).data }
                                //     }
                                // )
                                setDeviceTree(tree);
                                setPowerData(ret);
                                setDisplayedData({ data: convertedData });
                                setDisplayedBarChartData({ data: convertDataToBarChartFormat(ret, props.devices).data })
                                setDataLoading(false);
                            }


                        })
                        .catch(err => {
                            setDataLoading(false);
                            setErrorMessage('Error loading data ' + err.message)
                        })
                }
            })

    }

    function convertDataToBarChartFormat(from: IPowerUsageEntry[], devices: IDevice[]): IBarChartData {
        const barChartData: IBarChartData = { data: [] }
        const chartDataMap: Map<Date, { id: string, usage: number }[]> = new Map();

        from.forEach(powerEntry => {
            if (!chartDataMap.get(powerEntry.timestamp)) {
                chartDataMap.set(powerEntry.timestamp, [])
            }
            const mapEntry = chartDataMap.get(powerEntry.timestamp)
            if (isControlPointSelected(powerEntry.deviceId)) {

                mapEntry?.push({ id: powerEntry.deviceId, usage: parseInt(powerEntry.usage + '', 10) })
            }
        })

        chartDataMap.forEach((value, key) => {

            const barValues: any = { timestamp: moment(key).format('yyyy-MM-DD hh:mm'), timestampDate: key }
            value.forEach(val => {
                const cpName = mapLegacyCPIdToDeviceName(val.id, devices) + ' ' + val.id.substring(0, 8)
                barValues[cpName] = val.usage;
            })
            barChartData.data.push(barValues);
        })

        barChartData.data = barChartData.data.sort(
            (a, b) => {
                const bT = new Date(b['timestampDate'])
                const aT = new Date(a['timestampDate'])
                const diff = aT.valueOf() - bT.valueOf()
                return diff
            }
        )

        // barChartData.data = barChartData.data.map(val => {
        //     delete val['timestampDate'];
        //     return val;

        // })
        console.log(JSON.stringify(barChartData))

        return barChartData
    }

    function convertData(from: IPowerUsageEntry[], devices: IDevice[]): IChartData {
        const chartData: IChartData = { data: [] }

        const chartDataMap: Map<string, { timestamp: Date, usage: number }[]> = new Map()

        from.forEach(powerEntry => {

            const mapEntry = chartDataMap.get(powerEntry.deviceId);
            if (!mapEntry) {
                chartDataMap.set(powerEntry.deviceId, []);
            }
            const mapEntryGet = chartDataMap.get(powerEntry.deviceId);
            mapEntryGet?.push({ timestamp: powerEntry.timestamp, usage: powerEntry.usage })
        })

        chartDataMap.forEach((value, key) => {
            const dataXY = value.map(val => {
                let usageVal: number = parseInt(val.usage + '', 10)

                // if (usageVal < 1) {
                //     usageVal = 1;
                // }

                return { x: new Date(val.timestamp), y: usageVal, label: 'test ' }

            })
            chartData.data.push({ id: mapLegacyCPIdToDeviceName(key, devices), cpId: key, data: dataXY })
        })

        // console.log(JSON.stringify(chartData))
        return chartData;
    }


    function convertData2(from: IPowerUsageEntry[], devices: IDevice[]): IChartData {
        const chartData: IChartDataInt = { data: [] }

        const chartDataMap: Map<string, { data: { timestamp: Date, usage: number }[] }> = new Map()

        from.forEach(powerEntry => {

            if (isControlPointSelected(powerEntry.deviceId)) {


                const mapEntry = chartDataMap.get(powerEntry.deviceId);
                if (!mapEntry) {
                    chartDataMap.set(powerEntry.deviceId, { data: [] });
                }
                const mapEntryGet = chartDataMap.get(powerEntry.deviceId);
                // if (mapEntryGet) 

                mapEntryGet?.data.push({ timestamp: powerEntry.timestamp, usage: parseInt(powerEntry.usage + '', 10) })
            }

        })

        chartDataMap.forEach((value, key) => {
            const dataXY = value.data.map(val => {
                let usageVal: number = val.usage
                if (usageVal === undefined) {
                    usageVal = 0;
                }
                return { x: new Date(val.timestamp), y: usageVal, label: 'test ' }
            })

            let totalCalc = 0;
            value.data.forEach(entry => {
                totalCalc += entry.usage;
            })

            chartData.data.push({ id: mapLegacyCPIdToDeviceName(key, devices) + ' ' + key, cpId: key, data: dataXY, total: totalCalc })
        })

        // const mappedData = chartData.data.map( item => { 
        //     return item;
        // })

        const idTotals: { id: string, total: number }[] = [];
        chartData.data.forEach(item => {
            if (item.id && item.total !== undefined) {
                idTotals.push({ id: item.id, total: item.total })
            }
        })

        const ordered = idTotals.sort((a, b) => a.total - b.total);



        let filteredChartData: IChartData = { data: [] };

        ordered.forEach(val => {

            const item = chartData.data.find(item => item.id === val.id);
            if (item && item.data.length > 0) {
                filteredChartData.data.push(
                    {
                        id: item.id,
                        cpId: item.cpId,
                        data: (
                            item.data.map(
                                itemdataVal => {
                                    return { x: itemdataVal.x, y: itemdataVal.y }
                                })
                        )
                    })
            }

        })
        // chartData.data.forEach(item => {
        //     if (item.id && item.data.length > 0) {

        //         filteredChartData.data.push({ id: item.id, data: item.data })
        //     }
        // })

        // filteredChartData = JSON.parse( JSON.stringify(filteredChartData))

        // console.log(JSON.stringify(filteredChartData))
        return filteredChartData;
    }

    function mapLegacyCPIdToDeviceName(cpId: string, devices: IDevice[]): string {

        const deviceId = cpId.substr(0, 36);
        const cpExtension = cpId.substr(36, 3).trim()
        const foundDevice = devices.find(dev => dev.id === deviceId);

        if (foundDevice) {

            switch (foundDevice.deviceType) {
                case '1gdimmer':
                case '1g3w10ax':
                    return foundDevice.outputName ? foundDevice.outputName : 'outputName undefined';
                case '2ggpo':
                    {
                        switch (cpExtension) {
                            case 'L':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'R':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return '2ggpo cp ext not found';
                        }
                    }
                case '1gfan':
                    {
                        switch (cpExtension) {
                            case 'S':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'F':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return '1gfan cp ext not found';
                        }
                    }
                case 'mccoy':
                    {
                        switch (cpExtension) {
                            case 'C':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'D':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            case 'E':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'G':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return 'mccoy cp ext not found';
                        }
                    }
                default:
                    switch (cpExtension) {
                        case '_0':
                            return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                        case '_1':
                            return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                        case '_2':
                            return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                        case '_3':
                            return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                        default:
                            return 'default cp ext not found';
                    }
                    // return cpId;
            }


        } else {
            return cpId;
        }

    }

    function getLegacyControlpointIdsForDevice(device: IDevice): string[] {

        switch (device.deviceType) {
            case 'zcc':
            case 'ugds':
            case 'ugdc':
                return [];
            case '1gdimmer':
            case '1g3w10ax':
                return [device.id];
            case '2ggpo':
                return [device.id + 'L', device.id + 'R'];
            case 'mccoy':
                return [device.id + 'C', device.id + 'D', device.id + 'E', device.id + 'G'];
            case '1gfan':
                return [device.id + 'S', device.id + 'F'];
            default:
                return [device.id + '_0', device.id + '_1', device.id + '_2' , device.id + '_3' ];
        }
    }



    const barChartKeysMap: Set<string> = new Set();
    displayedBarChartData.data.forEach(times => {
        Object.keys(times).forEach(id => {
            if (id !== 'timestamp' && id !== 'timestampDate') {
                barChartKeysMap.add(id)
            }
        })
    })

    const barChartKeys: string[] = []

    barChartKeysMap.forEach(key => {
        barChartKeys.push(key);
    })


    return (


        <Grid container={true} id='network_powerusage_container' >

            <Grid item xs={6}>

                <DateRangePicker
                    startDate={startDate && startDate}
                    endDate={endDate}
                    onDateChanged={(startDate, endDate) => {
                        if (startDate)
                            setStartDate(startDate);
                        if (endDate)
                            setEndDate(endDate);
                    }}
                    onResolutionChanged={(resolution => {
                        setResolution(resolution)
                    })}
                />
            </Grid>
            <Grid item xs={6}> </Grid>


            <Grid item xs={3}>
                <LoadingButton id='network_powerusage_load_btn' variant={"contained"} loading={dataLoading} onClick={loadPowerData}>Load Power Data</LoadingButton>
            </Grid>

            {/* {this.powerTreeDisplay()} */}
            {props.org?.id && getPieChatForNetwork(props.org?.id)}

            {false && selectedNodes.nodes.length > 0 && (
                <div>

                    <span>
                        Filtered Data.
                    </span>
                    <span>

                        {selectedNodes.nodes.map(node => {
                            return <span> Network: {node.networkId} , Rooms: {Array.from(node.roomNames).map(roomName => roomName)} </span>
                        })}
                    </span>
                </div>
            )}

            <div id='c3chart' style={{ height: '600px' }} />
            <div id='c3chartline' style={{ height: '600px' }} />

            {
                <Snackbar autoHideDuration={5000} open={errorMessage !== undefined} onClose={() => {
                    setErrorMessage(undefined);
                }} >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            }

            {renderChart()}
        </Grid >

    );

}

// const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {
//     return { devices: state.blueprintState.deviceState.devices, orgs: state.blueprintState.orgState.orgs }
// }


export default (NetworkPowerUsageChart);