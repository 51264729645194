import { RegisterDecoder } from "libs/RegisterDecoder";
import { IDevice, IOrganization } from "./blueprint";
import { IZimiControlpoint, IZimiControlpointBlind, IZimiControlpointDimmer, IZimiControlpointDoor, IZimiControlpointFan, IZimiControlpointLight, IZimiControlpointOutlet, IZimiControlpointSwitch, IZimiNetwork } from "./types";

const convertOrg = (org: IOrganization): IZimiNetwork => {
    return {
        id: org.id,
        name: org.name || org.defaultAlias,
        timezone: org.timezone ? org.timezone : '',
        lat: org.latitude ? org.latitude : 0,
        long: org.longitude ? org.longitude : 0,
        address: {
            street: org.address?.streetAddress,
            suburb: org.address?.city,
            postcode: org.address?.postalCode,
            country: org.address?.countryCode,
        },
        meta: {
            devices: org.meta.devices,
            users: org.meta.users
        }
    }
}

export const convertOrgs = (orgs: IOrganization[]): IZimiNetwork[] => {
    return orgs.map(org => convertOrg(org));

}

const deviceToControlPoints = (device: IDevice, network: IOrganization | undefined): IZimiControlpoint[] => {
    const roomNames = network?.roomNames?.split(',');

    const devParams = RegisterDecoder.getInstance().decodeRegistersForControlHistory(device,
        roomNames ? roomNames : ['']);

    const cpsList: IZimiControlpoint[] = [];

    devParams?.forEach(dev => {

        const cpId = dev.controlpointId;
        const cpName = dev.deviceName ? dev.deviceName : '';
        const cpRoomname = dev.roomName ? dev.roomName : '';

        switch (dev.outletType) {
            case 'blind':
                const cpBlind: IZimiControlpointBlind = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'BLIND',
                    control: {
                        openPercentage: dev.controlLevel !== undefined ? dev.controlLevel : -1
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber
                }
                cpsList.push(cpBlind);

                break;
            case 'dimmer':
            case 'dimmer0':
            case 'dimmer1':
            case 'dimmer2':
            case 'dimmer3':
            case 'dimmer4':
                const cpDimmer: IZimiControlpointDimmer = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'DIMMER',
                    control: {
                        brightness: dev.controlLevel !== undefined ? dev.controlLevel : -1,
                        isOn: dev.controlLevel !== undefined && dev.controlLevel > 0 ? true : false,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber

                }
                cpsList.push(cpDimmer);
                break;
            case 'fan':
                const cpFan: IZimiControlpointFan = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'FAN',
                    control: {
                        speed: dev.controlLevel ? fanSpeedToSetting(dev.controlLevel) : -1,
                        isOn: dev.controlLevel !== undefined && dev.controlLevel > 0 ? true : false,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber,

                }
                cpsList.push(cpFan);
                break;
            case 'garagedoor':

                const cpDoor: IZimiControlpointDoor = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'DOOR',
                    control: {
                        openPercentage: dev.controlLevel !== undefined ? dev.controlLevel : -1,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber,


                }
                cpsList.push(cpDoor);
                break;
            case 'gposwitch':
            case 'gposwitch0':
            case 'gposwitch1':

                const cpOutlet: IZimiControlpointOutlet = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'OUTLET',
                    control: {
                        isOn: dev.controlLevel !== undefined && dev.controlLevel > 0 ? true : false,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber,

                }
                cpsList.push(cpOutlet);
                break;
            case 'light':
                const cpLight: IZimiControlpointLight = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'LIGHT',
                    control: {
                        isOn: dev.controlLevel !== undefined && dev.controlLevel > 0 ? true : false,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber,

                }
                cpsList.push(cpLight);
                break;
            case 'switch':
            case 'switch0':
            case 'switch1':
            case 'switch2':
            case 'switch3':
            case 'switch4':
                const cpSwitch: IZimiControlpointSwitch = {
                    cpId: cpId,
                    name: cpName,
                    room: cpRoomname,
                    cpType: 'SWITCH',
                    control: {
                        isOn: dev.controlLevel !== undefined && dev.controlLevel > 0 ? true : false,
                    },
                    settings: {},
                    connected: device.connected,
                    deviceId: device.id,
                    serialNumber: device.serialNumber,

                }
                cpsList.push(cpSwitch);
                break;
            default:

                break;

        }
        // cpsList.push(cp);

    })

    return cpsList;
}

export const devicesToControlPoints = (devices: IDevice[], org: IOrganization | undefined): IZimiControlpoint[] => {

    const cpsList: IZimiControlpoint[] = [];

    devices.forEach(dev => {
        const cps = deviceToControlPoints(dev, org);
        cpsList.push(...cps)

    })
    return cpsList

}

const fanSpeedToSetting = (speed: number): number => {
    let speedValue = -1;
    // if (speed > 86) {
    //     speedValue = 'S8';
    // } else
    if (speed > 78) {
        speedValue = 7;
    } else if (speed > 64) {
        speedValue = 6;
    } else if (speed > 50) {
        speedValue = 5;
    } else if (speed > 35) {
        speedValue = 4;
    } else if (speed > 21) {
        speedValue = 3;
    } else if (speed > 7) {
        speedValue = 2;
    } else if (speed > 0) {
        speedValue = 1;
    } else {
        speedValue = 0;
    }
    return speedValue;
}