// material-ui
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { useDispatch, useSelector } from 'store';



// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { getNetworks } from 'zimi/data';
import { IZimiNetwork } from 'zimi/types';
import { useEffect, useState } from 'react';
import { orgsLoad } from 'store/slices/zimiBlueprint';
import useAuth from 'hooks/useAuth';
import { convertOrgs } from 'zimi/dataUtils';


import { IconBrandChrome, IconHelp, IconSitemap, IconBoxModel, IconCpu, IconCpu2 } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBoxModel,
    IconCpu,
    IconCpu2
};

// ==============================|| SAMPLE PAGE ||============================== //

const NetworkPage = () => {
    const dispatch = useDispatch();

    const user = useAuth().getCurrentUser()
    const theme = useTheme();


    useEffect(() => {
        user?.getIdToken()
        .then(jwt =>{
            if(jwt){
                dispatch(orgsLoad(jwt));
            }
        })
    }, []);



    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };


    const networkCard = (network: IZimiNetwork) => (
        <Grid item sm={6} md={3} key={'network_card_grid_' + network.id}>
            <Card sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
                <CardHeader
                    sx={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}
                    title={
                        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                            {network.name}
                        </Typography>
                    }
                />
                <CardContent>
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item>
                            <Typography variant="subtitle2" color="inherit">
                                Address: {`${network.address.street} ${network.address.suburb} ${network.address.postcode} ${network.address.country} `}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                Timezone: {network.timezone}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="inherit">
                                Device Count : {network.meta.devices?.count_all}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                User Count : {network.meta.users?.count}
                            </Typography>
                            <Typography variant="subtitle2" color="inherit">
                                Online : {network.meta.networkOnline === true ? 'True' : 'False'}
                            </Typography>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item>
                                <List component="nav" aria-label="main mailbox folders">
                                    <Link to={`/networks/${network.id}/controlpoints`}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ControlPointDuplicateIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Control Points" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to={`/networks/${network.id}/devices`}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <icons.IconCpu />
                                            </ListItemIcon>
                                            <ListItemText primary="Devices" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to={`/networks/${network.id}/notificationconfigs`}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <icons.IconSitemap />
                                            </ListItemIcon>
                                            <ListItemText primary="Notifications" />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
    
    const { orgs } = useSelector((state) => state.blueprint.orgState);

    const networksReturned = convertOrgs(orgs);
    const networkList = networksReturned.map((a) => networkCard(a));

    return (
        <MainCard>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    {networkList}

                    {/* { JSON.stringify(orgs)}  */}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default NetworkPage;
